import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { TypeFilterEnum } from '@shared/enum/type-filter.enum';
import { RadioButtonModule } from 'primeng/radiobutton';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { PopoverModule } from 'primeng/popover';
@Component({
    selector: 'va-types-filter',
    templateUrl: './types-filter.component.html',
    standalone: true,
    imports: [
        PopoverModule,
        RadioButtonModule,
        FormsModule,
        TranslateModule,
    ],
})
export class TypesFilterComponent implements OnInit {
  private _type: { label: string; key: string };

  @Input()
  set type(type: { label: string; key: string }) {
    this._type = type;
    this.selected = this.types[this.types.findIndex((el) => el.key === type.key)];
  }

  get type(): { label: string; key: string } {
    return this._type;
  }
  @Output() typeChange: EventEmitter<{
    label: string;
    key: string;
  }> = new EventEmitter<{ label: string; key: string }>();

  types: Array<{ label: string; key: string }> = [
    {
      label: 'COMMONS.TYPES.RANGE',
      key: TypeFilterEnum.RANGE,
    },
    {
      label: 'COMMONS.TYPES.SINGLE',
      key: TypeFilterEnum.SINGLE,
    },
  ];

  selected: { label: string; key: string };

  overlayStyle = {
    backgroundColor: '#fff',
    boxShadow: '0px 10px 20px -10px rgba(0, 0, 0, 0.75)',
    width: 'auto',
    margin: 0,
    zIndex: '99999',
  };

  constructor() {}

  ngOnInit(): void {
    console.log();
  }
}
