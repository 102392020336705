import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation, inject } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '@core/services/user.service';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { ColumnTable } from '@shared/interfaces/column-table.interface';
import { VehicleTrackingMap } from '@shared/interfaces/vehicle-tracking-map.interface';
import { MapService } from '@shared/services/map.service';
import { MenuItem, PrimeTemplate } from 'primeng/api';
import { RolesType } from '@core/enums/roles-type.enum';
import { TableModule } from 'primeng/table';
import { BadgeChargeComponent } from '../badge/badge-charge.component';
import { ButtonEventComponent } from '../button-event.component';
import { TableMenuComponent } from '../table/table-menu.components';
import { MenuItemsMapMarkerClusterInfoPipe } from './menu-items-map-marker-cluster-info.pipe';

@Component({
    selector: 'va-map-marker-cluster-info',
    template: `
      <div class="marker-cluster-info">
        <p-table
          styleClass="p-datatable-striped"
          [size]="'small'"
          responsiveLayout="scroll"
          [value]="markers"
          [rowHover]="true"
          [paginator]="true"
          [globalFilterFields]="['visualId']"
          [rows]="3"
        >
          <ng-template pTemplate="header">
            <tr>
              <th class="flex align-items-center">
                {{ 'PAGES.ASSETS.VEHICLES.MAP.TABLE.VISUAL_ID' | translate }}
                <p-columnFilter
                  type="text"
                  field="visualId"
                  display="menu"
                  [showMatchModes]="false"
                  [showOperator]="false"
                  [showAddButton]="false"
                ></p-columnFilter>
              </th>
              <th>
                {{ 'PAGES.ASSETS.VEHICLES.MAP.TABLE.VEHICLE_ID' | translate }}
              </th>
              <th>
                {{ 'FLEET' | translate }}
              </th>
              <th>
                {{ 'PAGES.ASSETS.VEHICLES.MAP.TABLE.CHARGE' | translate }}
              </th>
              <th>
                {{ 'PAGES.ASSETS.VEHICLES.MAP.TABLE.MODEL' | translate }}
              </th>
              <th>
                {{ 'PAGES.ASSETS.VEHICLES.MAP.TABLE.CATEGORY' | translate }}
              </th>
              <th></th>
              <th></th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-marker>
            <tr>
              <td>{{ marker.visualId }}</td>
              <td>{{ marker.id }}</td>
              <td>{{ marker.fleet }}</td>
              <td>
                <va-badge-charge [charge]="marker.stateOfCharge"></va-badge-charge>
              </td>
              <td>{{ marker.vehicleModel }}</td>
              <td>{{ marker.vehicleCategory }}</td>
              <td>
                <va-button-event
                  [icon]="'fas fa-map-marker-alt icon-pointer'"
                  (clickIcon)="clickIcon(marker.id)"
                ></va-button-event>
              </td>
              <td>
                <va-table-menu
                  [label]="'COMMONS.TABLE.MENU_TABLE.VIEW' | translate"
                  [link]="['vehicle', 'details', marker.id.toString()]"
                  [items]="marker | menuItemsMapMarkerClusterInfo"
                ></va-table-menu>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    `,
    standalone: true,
    imports: [
        TableModule,
        PrimeTemplate,
        BadgeChargeComponent,
        ButtonEventComponent,
        TableMenuComponent,
        TranslateModule,
        MenuItemsMapMarkerClusterInfoPipe
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MapMarkerClusterInfoComponent {
  private userService = inject(UserService);
  private mapService = inject(MapService);

  @Input() markers: VehicleTrackingMap[];
  columns: ColumnTable[];
  appId: string;

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);

  constructor() {
    this.appId = this.userService.getAppId();
  }

  clickIcon(markerId: number): void {
    this.mapService.setMarkerId(markerId);
  }

}
