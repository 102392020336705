import { Component, OnInit, Input, OnDestroy, inject } from '@angular/core';
import { ControlContainer, NgModelGroup, FormsModule } from '@angular/forms';
import { OperatorFilterEnum } from '@shared/enum/operator-filter.enum';
import { TypeFilterEnum } from '@shared/enum/type-filter.enum';
import { MultiSelectionInput } from '@shared/models/filters/input-filter-multiselection.class';
import { FiltersService, ResetFilter } from '@shared/services/filters.service';
import { Subscription } from 'rxjs';
import { InputLayoutComponent } from '../input-layout/input-layout.component';
import { MultiSelectModule } from 'primeng/multiselect';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'va-input-multiselection',
    templateUrl: './input-multiselection.component.html',
    viewProviders: [{ provide: ControlContainer, useExisting: NgModelGroup }],
    standalone: true,
    imports: [
        InputLayoutComponent,
        MultiSelectModule,
        FormsModule,
        TranslateModule,
    ],
})
export class InputMultiselectionComponent implements OnInit, OnDestroy {
  private filterService = inject(FiltersService);

  @Input() filterItem: MultiSelectionInput;

  value: any[];
  operator: OperatorFilterEnum | OperatorFilterEnum[];
  type: TypeFilterEnum;
  subscription: Subscription;

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);

  constructor() {}

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit(): void {
    this.type = this.filterItem.typeFilter;
    this.operator = this.type === 'Range' ? this.filterItem.operatorRange : this.filterItem.operatorSingle;

    this.getResetFilters();
  }

  operatorChange(event: any): void {
    this.operator = event;
  }

  typeChange(event: any): void {
    this.type = event;
    this.operator = this.type === 'Range' ? this.filterItem.operatorRange : this.filterItem.operatorSingle;
  }

  resetInput(): void {
    this.operator =
      this.filterItem.typeFilter === 'Range' ? this.filterItem.operatorRange : this.filterItem.operatorSingle;
    this.value = null;
  }

  restoreInput(field: any): void {
    this.value = field.value;
  }

  private getResetFilters(): void {
    this.subscription = this.filterService.getResetFilters().subscribe((state: ResetFilter) => {
      switch (state.type) {
        case 'restore':
          break;
        case 'resetAll':
          this.checkState(state.reset);
          break;
        case 'resetSingle':
          if (state.id === this.filterItem.key) {
            this.checkState(state.reset);
          }
          break;
      }
    });
  }

  private checkState(flag: boolean): void {
    if (flag) {
      this.value = null;
    }
  }
}
