import { ActivatedRoute, RouterOutlet } from '@angular/router';
import { Component, OnInit, inject } from '@angular/core';

@Component({
    selector: 'va-empty-layout',
    template: `<router-outlet></router-outlet>`,
    standalone: true,
    imports: [RouterOutlet],
})
export class EmptyLayoutComponent implements OnInit {
  private route = inject(ActivatedRoute);

  appId: string;

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);

  constructor() {}

  ngOnInit(): void {
    this.route.params.subscribe((param) => {
      if (param['appId']) {
        this.appId = param['appId'];
      }
    });
  }
}
