import { FractionPipe } from './fraction.pipe';
import { PercentPipe } from '@angular/common';
import { Pipe, PipeTransform, inject } from '@angular/core';
import { OptionalValuePipe } from './option-value.pipe';

@Pipe({
    name: 'percentagePipe',
    standalone: true,
})
export class PercentagePipe implements PipeTransform {
  private optionalValuePipe = inject(OptionalValuePipe);
  private percentPipe = inject(PercentPipe);
  private fractionPipe = inject(FractionPipe);

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);

  constructor() {}

  transform(value: any, divider: number): any {
    if (this.isNullOrEmptyString(value)) {
      return this.optionalValuePipe.transform(value);
    }

    if (!divider) {
      divider = 1;
    }

    if (value == 0) {
      return this.percentPipe.transform(value);
    }

    let val = this.fractionPipe.transform(value, divider);

    return this.percentPipe.transform(val);
  }

  private isNullOrEmptyString(val: any): boolean {
    return val === null || val === '' || val === undefined;
  }
}
