import { Component, Input, OnInit } from '@angular/core';
import { ProgressBarModule } from 'primeng/progressbar';
import { PercentagePipe } from '../pipes/percentage.pipe';

@Component({
    selector: 'va-progress-bar',
    template: `
    <div
      class="percentage"
      [class.color-gray]="!charge"
      [class.color-red]="charge === 0 && charge >= 0 && charge < 25"
      [class.color-yellow]="charge && charge >= 25 && charge < 75"
      [class.color-green]="charge && charge >= 75 && charge <= 100"
    >
      {{ charge === null ? 'N.A.' : (charge | percentagePipe: 100) }}
    </div>
    <p-progressBar
      [class.red]="charge !== undefined && charge >= 0 && charge < 25"
      [class.yellow]="charge !== undefined && charge >= 25 && charge < 75"
      [class.green]="charge !== undefined && charge >= 75 && charge <= 100"
      [showValue]="showValue"
      [value]="charge"
    ></p-progressBar>
  `,
    standalone: true,
    imports: [ProgressBarModule, PercentagePipe],
})
export class ProgressBarComponent implements OnInit {
  @Input() charge: number;
  @Input() showValue = false;

  constructor() {}

  ngOnInit(): void {
    console.log();
  }
}
