import { Injectable, inject } from '@angular/core';
import { ConfigService } from '@core/services/config.service';
import { SessionService } from '@core/services/session.service';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MapService {
  private configService = inject(ConfigService);
  private sessionService = inject(SessionService);

  private mapLoaded$: BehaviorSubject<boolean>;
  private markerId$: BehaviorSubject<number>;
  private mapBarState$: BehaviorSubject<boolean>;
  private mapTheme$: BehaviorSubject<string>;

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);

  constructor() {
    let config = this.sessionService.getItem('config');

    this.mapLoaded$ = new BehaviorSubject<boolean>(false);
    this.markerId$ = new BehaviorSubject<number>(null);
    this.mapBarState$ = new BehaviorSubject<boolean>(config.map.mapBarEnabled);
    this.mapTheme$ = new BehaviorSubject<string>(config.map.mapTheme);

    this.getConfig();
  }

  setMapLoaded(loaded: boolean): void {
    this.mapLoaded$.next(loaded);
  }

  getMapLoaded(): Observable<boolean> {
    return this.mapLoaded$.asObservable();
  }

  setMapBarState(state: boolean): void {
    this.mapBarState$.next(state);
  }

  getMapBarState(): Observable<boolean> {
    return this.mapBarState$.asObservable();
  }

  setMapTheme(theme: string): void {
    this.mapTheme$.next(theme);
  }

  getMapTheme(): Observable<string> {
    return this.mapTheme$.asObservable();
  }

  setMarkerId(id: number): void {
    this.markerId$.next(id);
  }

  getMarkerId(): Observable<number> {
    return this.markerId$.asObservable();
  }

  private getConfig(): void {
    this.configService.getConfig().subscribe((res) => {
      this.setMapBarState(res.map.mapBarEnabled);
      this.setMapTheme(res.map.mapTheme);
    });
  }
}
