import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  inject,
} from '@angular/core';
import {
  GoogleMap,
  MapInfoWindow,
  MapMarker,
  MapMarkerClusterer,
  MapPolygon,
  MapTransitLayer,
} from '@angular/google-maps';
import { MenuItem } from 'primeng/api';
import { HttpClient } from '@angular/common/http';
import { ThemeMap } from '@shared/enum/theme-map.enum';
import { VehicleTrackingMap } from '@shared/interfaces/vehicle-tracking-map.interface';
import { MapService } from '@shared/services/map.service';
import { catchError, map } from 'rxjs/operators';
import { Subscription, Observable, of } from 'rxjs';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { LatLng } from '@shared/interfaces/map-configuration.interface';
import { SubSink } from 'subsink';
import _ from 'lodash';

declare const google: any;

import lightMonochrome from './styles/light-monochrome.json';
import darkMonochrome from './styles/dark-monochrome.json';
import grayMonochrome from './styles/gray-monochrome.json';
import { GeoFenceDto, GeoFenceService } from '@codegen/Vaimoo.Admin.API';
import { NgStyle, NgFor, AsyncPipe } from '@angular/common';
import { MapMarkerInfoComponent } from './map-marker-info.component';
import { MapMarkerClusterInfoComponent } from './map-marker-cluster-info.component';
import { MenubarModule } from 'primeng/menubar';
import { InputTextModule } from 'primeng/inputtext';
import { MapFilterComponent } from './map-filter.component';

const maxLat = (Math.atan(Math.sinh(Math.PI)) * 180) / Math.PI;

export let MAP_CONFIG = {
  width: '100%',
  height: '650px',
  zoom: 12,
  options: {
    styles: grayMonochrome,
    gestureHandling: 'cooperative',
    fullscreenControl: true,
    mapTypeControl: false,
    streetViewControl: false,
    keyboardShortcuts: false,
    minZoom: 3,
    maxZoom: 22,
    restriction: {
      latLngBounds: { north: maxLat, south: -maxLat, west: -180, east: 180 },
      strictBounds: true,
    },
  } as google.maps.MapOptions,
  imageClusterer: '',
  clusterClass: 'cluster',
  clusterStyles: [
    {
      width: 20,
      height: 20,
      className: 'cluster-green',
    },
    {
      width: 30,
      height: 30,
      className: 'cluster-orange',
    },
    {
      width: 40,
      height: 40,
      className: 'cluster-red',
    },
  ],
};

@Component({
  selector: 'va-map',
  template: `
    <div class="map-container" [ngStyle]="style">
      @if ((apiLoaded | async) && load && markers.length > 0) {
      <google-map
        #map="googleMap"
        [zoom]="zoom"
        [width]="width"
        [height]="height"
        [center]="center"
        [options]="options"
        (mapInitialized)="boundsChange($event)"
        (zoomChanged)="zoomChanged(map)"
      >
        <map-polygon *ngFor="let poly of polygons" [paths]="poly.paths" [options]="poly.options">
        </map-polygon>

        <map-transit-layer>
          <map-marker-clusterer
            #clusterer
            [minimumClusterSize]="10"
            [zoomOnClick]="zoomOnClick"
            [clusterClass]="clusterClass"
            [calculator]="setCustomClusterStyle"
            [options]="{
              maxZoom: 22,
              gridSize: 40
            }"
            [styles]="clusterStyles"
            (clusterClick)="openInfoCard($event, null, null)"
          >
            @for (m of markers; track m) {
            <map-marker
              #marker="mapMarker"
              [position]="m.position"
              [options]="m.options"
              (mapClick)="openInfoCard(null, marker, m)"
            >
            </map-marker>
            }
          </map-marker-clusterer>
          <map-info-window>
            @if (!isClusterInfo && mapInfoContent) {
            <va-map-marker-info [infoContent]="mapInfoContent"></va-map-marker-info>
            } @if (isClusterInfo) {
            <va-map-marker-cluster-info
              [markers]="markersOfCluster"
              (clickIcon)="markerId = $event"
            ></va-map-marker-cluster-info>
            }
          </map-info-window>
        </map-transit-layer>
      </google-map>
      } @if (!load) {
      <div class="emptymessage skeleton-loader-full">
        <i class="pi pi-spin pi-spinner icon-loader"></i>
        {{ 'COMMONS.TABLE.LOADING_MESSAGE' | translate }}
      </div>
      } @if (mapBarEnabled) {
      <div class="map-menubar-container">
        <p-menubar class="map-menubar" [model]="items">
          <input #mapSearchField class="input-search" type="search" pInputText placeholder="Search" />
        </p-menubar>
        <va-map-filter [(menuState)]="filterState" [(markers)]="markers"></va-map-filter>
      </div>
      }
    </div>
  `,
  styles: [
    `
      .map-container {
        width: 100%;
        height: 100%;
        border: 1px solid #dee2e6;
        border-radius: 3px;
        transition: 0.3s;
        margin: 0 0 10px 0;
      }

      .icon-loader {
        font-size: 24px;
        margin: 10px;
      }

      /*.emptymessage {*/
      /*  position: absolute;*/
      /*  width: 100%;*/
      /*  height: 100%;*/
      /*  display: flex;*/
      /*  font-size: 24px;*/
      /*  font-weight: bold;*/
      /*  justify-content: center;*/
      /*  align-items: center;*/
      /*  border: 1px solid #dee2e6;*/
      /*  border-radius: 3px;*/
      /*  top: 0;*/
      /*}*/

      .map-menubar {
        position: absolute;
        top: 10px;
        left: 20px;
        width: auto;
      }

      .input-search {
        margin-left: 10px;
      }
    `,
  ],
  standalone: true,
  imports: [
    NgStyle,
    GoogleMap,
    NgFor,
    MapPolygon,
    MapTransitLayer,
    MapMarkerClusterer,
    MapMarker,
    MapInfoWindow,
    MapMarkerInfoComponent,
    MapMarkerClusterInfoComponent,
    MenubarModule,
    InputTextModule,
    MapFilterComponent,
    AsyncPipe,
    TranslateModule,
  ],
})
export class MapComponent implements OnInit, OnDestroy, AfterViewInit {
  private mapService = inject(MapService);
  private geofenceService = inject(GeoFenceService);
  private translateService = inject(TranslateService);
  private httpClient = inject(HttpClient);

  @ViewChild('mapRef') mapRef: HTMLElement;
  @ViewChild('mapSearchField') searchField: ElementRef;
  @ViewChild(GoogleMap, { static: false }) map: GoogleMap;
  @ViewChild(MapInfoWindow, { static: false }) mapInfoWindow: MapInfoWindow;
  @ViewChild(MapMarker, { static: false }) mapMarker: MapMarker;
  @ViewChild(MapMarkerClusterer, { static: false })
  clusterer: MapMarkerClusterer;

  @Input() style: any;
  @Input() width: string = MAP_CONFIG.width;
  @Input() height: string = MAP_CONFIG.height;
  @Input() load = false;
  @Input() zoom: number = MAP_CONFIG.zoom;
  @Input() center: LatLng;
  @Input() options: google.maps.MapOptions = MAP_CONFIG.options;
  @Input() imageClusterer: string = MAP_CONFIG.imageClusterer;
  @Input() clusterClass: string = MAP_CONFIG.clusterClass;
  @Input() clusterStyles: any = MAP_CONFIG.clusterStyles;
  @Input() mapBarEnabled = false;
  @Input() markers: VehicleTrackingMap[] = [];

  apiLoaded: Observable<boolean>;
  filterState: 'out' | 'in' = 'out';
  markerId: number;
  zoomOnClick = false;
  isClusterInfo = false;
  mapInfoContent: VehicleTrackingMap;
  markersOfCluster: google.maps.Marker[];
  subscription: Subscription;
  items: MenuItem[] = [
    {
      label: 'Settings',
      icon: 'pi pi-cog',
      items: [
        {
          label: 'Themes',
          icon: 'pi pi-theme',
          items: [
            {
              label: 'Light',
              command: () => {
                MAP_CONFIG.options.styles = lightMonochrome;
                this.map.googleMap.setOptions(MAP_CONFIG.options);
                this.mapService.setMapTheme(ThemeMap.LIGHT_MONOCHROME);
              },
            },
            {
              label: 'Gray',
              command: () => {
                MAP_CONFIG.options.styles = grayMonochrome;
                this.map.googleMap.setOptions(MAP_CONFIG.options);
                this.mapService.setMapTheme(ThemeMap.GRAY_MONOCHROME);
              },
            },
            {
              label: 'Dark',
              command: () => {
                MAP_CONFIG.options.styles = darkMonochrome;
                this.map.googleMap.setOptions(MAP_CONFIG.options);
                this.mapService.setMapTheme(ThemeMap.DARK_MONOCHROME);
              },
            },
          ],
        },
      ],
    },
    {
      label: 'Filter',
      icon: 'pi pi-filter',
      command: () => (this.filterState = this.filterState === 'in' ? 'out' : 'in'),
    },
  ];

  polygons: { paths: LatLng[]; options: google.maps.PolygonOptions }[] = [];

  private subs = new SubSink();

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);

  constructor() {
    this.loadScript();
    this.getMapBar();
  }

  ngOnInit(): void {
    this.getServiceAreas();
    this.getMarkerIdOnClick();
    this.mapService.setMapBarState(this.mapBarEnabled);
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngAfterViewInit(): void {
    // if (this.mapBarEnabled) {
    //   this.subscription = this.mapService.getMapLoaded().subscribe((isLoaded) => {
    //     if (isLoaded) {
    //       const searchBox = new google.maps.places.SearchBox(this.searchField.nativeElement);
    //       searchBox.addListener('places_changed', () => {
    //         const places = searchBox.getPlaces();
    //         if (places.length === 0) {
    //           return;
    //         }
    //         places.forEach((place) => {
    //           if (!place.geometry || !place.geometry.location) {
    //             return;
    //           }
    //           const bounds = new google.maps.LatLngBounds();
    //           places.forEach((place) => {
    //             if (!place.geometry || !place.geometry.location) {
    //               return;
    //             }
    //             if (place.geometry.viewport) {
    //               bounds.union(place.geometry.viewport);
    //             } else {
    //               bounds.extend(place.geometry.location);
    //             }
    //           });
    //           this.map.fitBounds(bounds);
    //         });
    //       });
    //     }
    //   });
    // }
  }

  zoomChanged(map: GoogleMap): void {
    if (map.googleMap.getZoom() <= 22) {
      this.mapInfoWindow.close();

      /**
       * ? when clicked on the info window, on the zoom-in action, in order to center the marker,
       * ? apply a slight offset
       */
      const center = map.getCenter().toJSON();
      const adjustedCenter = { ...center, lat: center.lat + 0.0004 };
      this.center = adjustedCenter;
    }
  }

  boundsChange(map: google.maps.Map): void {
    if (!this.center) {
      let bounds = new google.maps.LatLngBounds();

      this.markers.forEach((el) => {
        bounds.extend(new google.maps.LatLng(el.position));
      });

      map.fitBounds(bounds);

      // rectangle
      let rectangle = new google.maps.Rectangle({
        bounds: bounds,
        visible: false,
        map: map,
      });
    }
  }

  // setCustomClusterStyle(cluster: VehicleTrackingMap[] | google.maps.Marker[]): ClusterIconInfo {
  setCustomClusterStyle(cluster: VehicleTrackingMap[] | google.maps.Marker[]) {
    if (cluster.some((bike) => _.inRange(bike?.stateOfCharge, 0, 24))) {
      return {
        text: cluster.length.toString(),
        title: ``,
        index: 3,
      };
    }
    if (cluster.some((bike) => _.inRange(bike?.stateOfCharge, 25, 74))) {
      return {
        text: cluster.length.toString(),
        title: ``,
        index: 2,
      };
    }
    if (cluster.some((bike) => _.inRange(bike?.stateOfCharge, 75, 101))) {
      return {
        text: cluster.length.toString(),
        title: ``,
        index: 1,
      };
    }
    return undefined;
  }

  openInfoTable(mapMarker: MapMarker, mapInfoWindow: MapInfoWindow, markerId: number): void {
    let marker: VehicleTrackingMap = this.markers.find((el) => el.id === markerId);

    this.mapInfoContent = marker;
    this.map.googleMap.setCenter(marker.position);
    this.center = this.map.getCenter().toJSON();

    mapInfoWindow.infoWindow.setPosition(marker.position);

    mapMarker.marker = new google.maps.Marker({
      position: marker.position,
      icon: marker.options.icon,
      anchorPoint: new google.maps.Point(0, -58), // align windowsInfo on center of marker
    });

    let bounds = new google.maps.LatLngBounds();
    this.map.fitBounds(bounds.extend(marker.position));
    this.map.zoom = 22;

    this.openInfoCard(null, this.mapMarker, marker);
  }

  openInfoCard(mapCluster: any, mapMarker: MapMarker, marker: VehicleTrackingMap): void {
    if (mapCluster) {
      this.isClusterInfo = true;
      this.markersOfCluster = mapCluster.getMarkers();
      this.mapInfoWindow.position = mapCluster.center_;
      this.center = mapCluster.center_;
    }

    if (mapMarker) {
      this.isClusterInfo = false;
      this.mapInfoContent = marker;
    }

    this.mapInfoWindow.open(mapMarker);
  }

  // private getThemeMap(): void {
  //   this.subscription = this.mapService.getMapTheme().subscribe((theme) => {
  //     this.load = false;
  //     switch (theme) {
  //       case 'lightMonochrome':
  //         this.setTheme(lightMonochrome);
  //         break;
  //       case 'grayMonochrome':
  //         this.setTheme(grayMonochrome);
  //         break;
  //       case 'darkMonochrome':
  //         this.setTheme(darkMonochrome);
  //         break;
  //       default:
  //         this.setTheme(lightMonochrome);
  //         break;
  //     }
  //   });
  // }

  // private setTheme(theme: google.maps.MapTypeStyle[]) {
  //   this.options.styles = theme;
  //   setTimeout(() => {
  //     this.load = true;
  //   }, 1000);
  // }

  private getServiceAreas(): void {
    this.geofenceService.geofenceGet('ServiceArea').subscribe((serviceAreas: GeoFenceDto[]) => {
      this.polygons = serviceAreas.map((geoFence) => this.mapGeoFenceToPolygon(geoFence));
    });
  }

  private mapGeoFenceToPolygon(geo: GeoFenceDto): {
    paths: google.maps.LatLngLiteral[];
    options: google.maps.PolygonOptions;
  } {
    const geoJson = JSON.parse(geo.fence);
    const coordinates = this.extractCoordinates(geoJson);

    return {
      paths: coordinates,
      options: this.getPolygonOptions(),
    };
  }

  private extractCoordinates(geoJson: any): google.maps.LatLngLiteral[] {
    return geoJson.features[0]?.geometry.coordinates[0]
      .filter(
        (coord: number[]) => coord.length === 2 && coord.every((num) => num !== null && num !== undefined),
      )
      .map(([lng, lat]: number[]) => ({ lng, lat }));
  }

  private getPolygonOptions(): google.maps.PolygonOptions {
    return {
      fillColor: 'blue',
      fillOpacity: 0.2,
      strokeColor: 'blue',
      strokeOpacity: 0.8,
      strokeWeight: 2,
      clickable: false,
      editable: false,
    };
  }

  private getMapBar(): void {
    this.subs.add(
      this.mapService.getMapBarState().subscribe((state) => {
        this.mapBarEnabled = state;
      }),
    );
  }

  private getMarkerIdOnClick(): void {
    this.subs.add(
      this.mapService.getMarkerId().subscribe((markerId) => {
        if (markerId && this.markers.length > 1) {
          this.isClusterInfo = false;
          this.markerId = markerId;
          this.center = this.markers.filter((el) => el.id === markerId)[0].position;
          if (this.mapMarker && this.mapInfoWindow) {
            this.openInfoTable(this.mapMarker, this.mapInfoWindow, this.markerId);
          }
        }
      }),
    );
  }

  private loadScript() {
    this.mapService.getMapLoaded().subscribe((load) => {
      if (!load) {
        this.apiLoaded = this.httpClient
          .jsonp(
            `https://maps.googleapis.com/maps/api/js?key=${
              environment.googleMapsKey
            }&libraries=geometry&language=${this.translateService.getDefaultLang()}`,
            'callback',
          )
          .pipe(
            map(() => {
              this.mapService.setMapLoaded(true);
              return true;
            }),
            catchError((e) => {
              console.error(e);
              return of(true);
            }),
          );
      } else {
        this.apiLoaded = of(true);
      }
    });
  }
}
