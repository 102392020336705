import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, inject } from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { SessionService } from '@core/services/session.service';
import { environment } from 'src/environments/environment';
import { filter, map } from 'rxjs/operators';
import { HeaderService } from '@layout/services/header.service';
import { SwUpdate } from '@angular/service-worker';
import { UserService } from '@core/services/user.service';
import { Observable } from 'rxjs';
import { AppConfigComponent } from './app-config.component';
import { NotificationComponent } from '@shared/components/notification.component';
import { SplashScreenComponent } from '@shared/components/splash-screen.component';
import { AsyncPipe } from '@angular/common';
import { PrimeNG } from 'primeng/config';
import { NgProgressbar } from 'ngx-progressbar';
import { NgProgressHttp } from 'ngx-progressbar/http';


@Component({
    selector: 'va-root',
    template: `
    @if (configEnabled) {
      <va-config></va-config>
    }
    <va-notification-toast></va-notification-toast>
    <p-confirmDialog [style]="{ width: '400px' }"></p-confirmDialog>
    @if ((sessionUserNotExist | async) === false || (sessionUserNotExist | async) === null || (sessionUserNotExist | async) === undefined) {
      <va-splash-screen></va-splash-screen>
    }
    <ng-progress
      ngProgressHttp
      [spinner]="false"
      [speed]="500">

    </ng-progress>
    <router-outlet></router-outlet>
    `,
    standalone: true,
    imports: [
        AppConfigComponent,
        NotificationComponent,
        SplashScreenComponent,
        NgProgressbar,
        NgProgressHttp,
        ConfirmDialogModule,
        RouterOutlet,
        AsyncPipe,
    ],
    providers: [
      HeaderService
    ]
})
export class AppComponent implements OnInit {
  private sessionService = inject(SessionService);
  private userService = inject(UserService);
  private primeNGConfig = inject(PrimeNG);
  private translateService = inject(TranslateService);
  private headerService = inject(HeaderService);
  private router = inject(Router);
  private update = inject(SwUpdate);

  configEnabled = environment.config;

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);

  constructor() {
    this.updateCheck();
  }

  updateCheck(): void {
    this.update
      .checkForUpdate()
      .then(() => console.log(`resolved`))
      .catch((err) => console.log(`rejected: ${err.message}`));
  }

  ngOnInit(): void {
    document.documentElement.style.fontSize = '14px';
    this.setLanguage();
    this.getRouterData();
  }

  get sessionUserNotExist(): Observable<boolean> {
    return this.userService.isLoggedIn$.asObservable();
  }

  private getRouterData(): void {
    this.router.events
      .pipe(
        filter((event: any) => event instanceof NavigationEnd),
        map(() => this.router),
      )
      .subscribe(() => {
        const breadcrumbs = this.getBreadcurms(this.router.routerState, this.router.routerState.root);
        const headerTitle = breadcrumbs[breadcrumbs.length - 1];
        this.headerService.setTitle(headerTitle);
      });
  }

  private getBreadcurms(state: any, parent: any): any[] {
    const data = [];
    if (parent && parent.snapshot.data && parent.snapshot.data.breadcrumb) {
      data.push(parent.snapshot.data.breadcrumb);
    }

    if (state && parent) {
      data.push(...this.getBreadcurms(state, state.firstChild(parent)));
    }
    return data;
  }

  private setLanguage(): void {
    const lang = this.sessionService.getItem('language');

    if (lang) {
      this.translateService.setDefaultLang(lang);
      this.translateService.use(lang);
    }

    this.translateService.get('PRIMENG').subscribe((res) => this.primeNGConfig.setTranslation(res));
  }
}
