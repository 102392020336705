import { Component, ElementRef, OnDestroy, OnInit, inject } from '@angular/core';
import { StorageKey } from '@core/config/global.config';
import { Config } from '@core/interfaces/config.interface';
import { ConfigService } from '@core/services/config.service';
import { SessionService } from '@core/services/session.service';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { SplashAnimationType } from '@shared/enum/splash-animation-type.enum';
import { Subscription } from 'rxjs';
import { NgClass, NgIf, NgFor } from '@angular/common';
import { ButtonDirective } from 'primeng/button';
import { FormsModule } from '@angular/forms';
import { CheckboxModule } from 'primeng/checkbox';
import { VersionComponent } from './shared/components/version.component';
import { PrimeNG } from 'primeng/config';
import { SelectModule } from 'primeng/select';

@Component({
    selector: 'va-config',
    template: `
    <div class="layout-config" [ngClass]="{ 'layout-config-active': active }">
      <div class="layout-config-content-wrapper">
        <a tabindex="0" class="layout-config-button" (click)="toggleConfigurator($event)">
          <i class="pi pi-cog"></i>
        </a>
        <a tabindex="0" class="layout-config-close" (click)="hideConfigurator($event)">
          <i class="pi pi-times"></i>
        </a>

        <div class="layout-config-content">
          <div>
            <fieldset class="fieldset-config">
              <legend class="panel-legend">Component Scale</legend>
              <div class="grid" *ngIf="this.config">
                <div class="col-12">
                  <div class="config-scale">
                    <button
                      icon="pi pi-minus"
                      type="button"
                      pButton
                      (click)="decrementScale()"
                      class="p-button-text"
                      [disabled]="scale === scales[0]"
                    ></button>
                    <i
                      class="pi pi-circle-fill"
                      *ngFor="let s of scales"
                      [ngClass]="{ 'scale-active': s === scale }"
                    ></i>
                    <button
                      icon="pi pi-plus"
                      type="button"
                      pButton
                      (click)="incrementScale()"
                      class="p-button-text"
                      [disabled]="scale === scales[scales.length - 1]"
                    ></button>
                  </div>
                </div>
              </div>
            </fieldset>

            <fieldset class="fieldset-config">
              <legend class="panel-legend">Component SplashScreen</legend>
              <div class="grid" *ngIf="this.config">
                <div class="col-12">
                  <p-select
                    [options]="animationTypes"
                    [(ngModel)]="config.splashScreen.animationType"
                    (ngModelChange)="updateConfig()"
                  ></p-select>
                </div>
              </div>
            </fieldset>

            <fieldset class="fieldset-config">
              <legend class="panel-legend">Component Language</legend>
              <div class="grid" *ngIf="this.config">
                <div class="col-12">
                  <p-checkbox
                    id="config.language.multilanguage"
                    [(ngModel)]="config.language.multilanguage"
                    (ngModelChange)="updateConfig()"
                    binary="true"
                    inputId="binary"
                  >
                  </p-checkbox>
                  <label for="config.language.multilanguage">{{'Enable Multilanguage' | translate}}</label>
                </div>
                <div class="col-12">
                  <p-select
                    [options]="langOptions"
                    [(ngModel)]="lang"
                    (ngModelChange)="updatedLang($event)"
                    [disabled]="!config.language.multilanguage"
                  ></p-select>
                </div>
              </div>
            </fieldset>

            <fieldset class="fieldset-config">
              <legend class="panel-legend">Component Sidebar</legend>
              <div class="grid" *ngIf="this.config">
                <div class="col-12">
                  <p-checkbox
                    id="config.sidebar.enableHideMenu"
                    [(ngModel)]="config.sidebar.enableHideMenu"
                    (ngModelChange)="updateConfig()"
                    binary="true"
                    inputId="binary"
                  >
                  </p-checkbox>
                  <label for="config.sidebar.enableHideMenu">{{'Enable All Menu Items' | translate}}</label>
                </div>
              </div>
            </fieldset>

            <fieldset class="fieldset-config">
              <legend class="panel-legend">Component Breadcrumbs</legend>
              <div class="grid" *ngIf="this.config">
                <div class="col-12">
                  <p-checkbox
                    id="config.breadcrumbs"
                    [(ngModel)]="config.breadcrumbs"
                    (ngModelChange)="updateConfig()"
                    binary="true"
                    inputId="binary"
                  >
                  </p-checkbox>
                  <label for="config.breadcrumbs">{{'Enable Breadcrumbs' | translate}}</label>
                </div>
              </div>
            </fieldset>

            <fieldset class="fieldset-config">
              <legend class="panel-legend">Component Map</legend>
              <div class="grid" *ngIf="this.config">
                <div class="col-12">
                  <p-checkbox
                    id="config.map.mapBarEnabled"
                    [(ngModel)]="config.map.mapBarEnabled"
                    (ngModelChange)="updateConfig()"
                    binary="true"
                    inputId="binary"
                  >
                  </p-checkbox>
                  <label for="config.map.mapBarEnabled">{{'Enable MapBar' | translate}}</label>
                </div>
                <div class="col-12">
                  <p-select
                    [options]="mapThemes"
                    [(ngModel)]="config.map.mapTheme"
                    (ngModelChange)="updateConfig()"
                    [disabled]="!config.map.mapBarEnabled"
                  ></p-select>
                </div>
              </div>
            </fieldset>

            <fieldset class="fieldset-config">
              <legend class="panel-legend">Component Filters</legend>
              <div class="grid" *ngIf="this.config">
                <div class="col-12">
                  <p-checkbox
                    id="config.filterAdvanced"
                    [(ngModel)]="config.filterAdvanced"
                    (ngModelChange)="updateConfig()"
                    binary="true"
                    inputId="binary"
                  >
                  </p-checkbox>
                  <label for="config.filterAdvanced">{{'Enable Filter Advanced' | translate}}</label>
                </div>
              </div>
            </fieldset>

            <fieldset class="fieldset-config">
              <legend class="panel-legend">Component Table</legend>
              <div class="grid" *ngIf="this.config">
                <div class="col-12">
                  <p-checkbox
                    id="config.table.columnSettingsState"
                    [(ngModel)]="config.table.columnSettingsState"
                    (ngModelChange)="updateConfig()"
                    binary="true"
                    inputId="binary"
                  >
                  </p-checkbox>
                  <label for="config.table.columnSettingsState">{{'Enable Column Settings' | translate}}</label>
                </div>
                <div class="col-12">
                  <p-select
                    [options]="columnsSettingsType"
                    [(ngModel)]="config.table.columnSettingsType"
                    (ngModelChange)="updateConfig()"
                    [disabled]="!config.table.columnSettingsState"
                  ></p-select>
                </div>
              </div>
            </fieldset>
          </div>

          <footer>
            <va-version></va-version>
          </footer>
        </div>
      </div>
    </div>
  `,
    standalone: true,
    imports: [
        NgClass,
        NgIf,
        ButtonDirective,
        NgFor,
        SelectModule,
        FormsModule,
        CheckboxModule,
        VersionComponent,
        TranslateModule,
    ],
})
export class AppConfigComponent implements OnInit, OnDestroy {
  private el = inject(ElementRef);
  private primeNGConfig = inject(PrimeNG);
  private translateService = inject(TranslateService);
  private configService = inject(ConfigService);
  private sessionService = inject(SessionService);

  active: boolean;
  outsideClickListener: any;

  scale = 14;
  scales: number[] = [12, 13, 14, 15, 16];

  subscription: Subscription;
  config: Config;

  animationTypes: any[] = [
    { value: SplashAnimationType.SlideLeft, label: 'Slide Left' },
    { value: SplashAnimationType.SlideRight, label: 'Slide Right' },
    { value: SplashAnimationType.FadeOut, label: 'Fade Out' },
  ];

  lang = this.sessionService.getItem('language')
    ? this.sessionService.getItem('language')
    : this.translateService.currentLang;
  langOptions: any[] = [
    { value: 'it', label: this.translateService.instant('LANGUAGE.ITALIAN') },
    { value: 'en', label: this.translateService.instant('LANGUAGE.ENGLISH') },
  ];

  mapThemes: any[] = [
    { value: 'lightMonochrome', label: 'Light' },
    { value: 'grayMonochrome', label: 'Gray' },
    { value: 'darkMonochrome', label: 'Dark' },
  ];

  columnsSettingsType: any[] = [
    { value: 'modal', label: 'Dialog Modal' },
    { value: 'overlay', label: 'Overlay Pannel' },
  ];

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);

  constructor() {}

  ngOnInit(): void {
    this.configService.getConfig().subscribe((res) => {
      this.config = res;
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  toggleConfigurator(event: Event): void {
    this.active = !this.active;
    event.preventDefault();

    if (this.active) {
      this.bindOutsideClickListener();
    } else {
      this.unbindOutsideClickListener();
    }
  }

  hideConfigurator(event: Event): void {
    this.active = false;
    this.unbindOutsideClickListener();
    event.preventDefault();
  }

  bindOutsideClickListener(): void {
    if (!this.outsideClickListener) {
      this.outsideClickListener = (event: any) => {
        if (this.active && this.isOutsideClicked(event)) {
          this.active = false;
        }
      };
      document.addEventListener('click', this.outsideClickListener);
    }
  }

  unbindOutsideClickListener(): void {
    if (this.outsideClickListener) {
      document.removeEventListener('click', this.outsideClickListener);
      this.outsideClickListener = null;
    }
  }

  isOutsideClicked(event: Event): boolean {
    return !(this.el.nativeElement.isSameNode(event.target) || this.el.nativeElement.contains(event.target));
  }

  decrementScale(): void {
    this.scale--;
    this.applyScale();
  }

  incrementScale(): void {
    this.scale++;
    this.applyScale();
  }

  applyScale(): void {
    document.documentElement.style.fontSize = this.scale + 'px';
  }

  updatedLang(lang: string): void {
    this.sessionService.setItem(StorageKey.language, lang);
    this.translateService.setDefaultLang(lang);
    this.translateService.use(lang);
    this.translateService.get('PRIMENG').subscribe((res) => this.primeNGConfig.setTranslation(res));
  }

  updateConfig(): void {
    this.configService.updateConfig(this.config);
  }
}
