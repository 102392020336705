import { Pipe, PipeTransform, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { OptionalValuePipe } from './option-value.pipe';

@Pipe({
    name: 'vehicleModelConverterPipe',
    standalone: true,
})
export class VehicleModelConverterPipe implements PipeTransform {
  private optionalValuePipe = inject(OptionalValuePipe);
  private transalteService = inject(TranslateService);

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);

  constructor() {}

  transform(value: number): string {
    if (this.isNullOrEmptyString(value)) {
      return this.optionalValuePipe.transform(value);
    }

    let val: string;

    switch (value) {
      case 1:
        val = 'COMMONS.ENUMS.VEHICLE_MODEL.FORTE';
        break;
      case 2:
        val = 'COMMONS.ENUMS.VEHICLE_MODEL.FORTE_22';
        break;
      case 3:
        val = 'COMMONS.ENUMS.VEHICLE_MODEL.LIBERA';
        break;
    }

    return this.transalteService.instant(val);
  }

  private isNullOrEmptyString(val: any): boolean {
    return val === null || val === '' || val === undefined;
  }
}
