import { Component, Input, OnInit, inject } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem } from 'primeng/api';
import { filter } from 'rxjs/operators';
import { BreadcrumbModule } from 'primeng/breadcrumb';

@Component({
    selector: 'va-breadcrumbs',
    template: `
    @if (enabled) {
      <div class="breadcrumbs">
        <p-breadcrumb [model]="menuItems"></p-breadcrumb>
      </div>
    }
    `,
    styles: [
        `
      .breadcrumbs {
        background: #f1f1f1;
        padding: 12px;
      }
    `,
    ],
    standalone: true,
    imports: [BreadcrumbModule],
})
export class BreadcrumbsComponent implements OnInit {
  private router = inject(Router);
  private activatedRoute = inject(ActivatedRoute);
  private translateService = inject(TranslateService);

  private _enabled = true;

  @Input()
  set enabled(flag: boolean) {
    this._enabled = flag;
    this.getBreadcrumbs();
  }

  get enabled(): boolean {
    return this._enabled;
  }

  menuItems: MenuItem[];

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);

  constructor() {
    this.getBreadcrumbs();
  }

  ngOnInit(): void {
    console.log();
  }

  private getBreadcrumbs(): void {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => (this.menuItems = this.createBreadcrumbs(this.activatedRoute.root)));
  }

  private createBreadcrumbs(
    route: ActivatedRoute,
    url: string = '',
    breadcrumbs: MenuItem[] = [],
  ): MenuItem[] {
    const children: ActivatedRoute[] = route.children;

    if (children.length === 0) {
      return breadcrumbs;
    }

    for (const child of children) {
      const routeURL: string = child.snapshot.url.map((segment) => segment.path).join('/');

      if (routeURL !== '') {
        url += `/${routeURL}`;
      }

      const label = child.snapshot.data.breadcrumb;
      if (label) {
        breadcrumbs.push({ label: this.translateService.instant(label), url });
      }

      return this.createBreadcrumbs(child, url, breadcrumbs);
    }
  }
}
