import { Injectable, inject } from '@angular/core';
import { ConfigService } from '@core/services/config.service';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TableService {
  private configService = inject(ConfigService);

  private columnSettingsState$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private columnSettingType$: BehaviorSubject<string> = new BehaviorSubject<string>('modal');

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);

  constructor() {
    this.configService.getConfig().subscribe((res) => {
      this.setColumnSettingsState(res.table.columnSettingsState);
      this.setColumnSettingsType(res.table.columnSettingsType);
    });
  }

  setColumnSettingsState(state: boolean): void {
    this.columnSettingsState$.next(state);
  }

  getColumnSettingsState(): Observable<boolean> {
    return this.columnSettingsState$.asObservable();
  }

  setColumnSettingsType(type: string): void {
    this.columnSettingType$.next(type);
  }

  getColumnSettingsType(): Observable<string> {
    return this.columnSettingType$.asObservable();
  }
}
