import { Component, Input, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { ButtonDirective, ButtonModule } from 'primeng/button';
import { RouterLink } from '@angular/router';
import { MenuModule } from 'primeng/menu';
import { RippleModule } from 'primeng/ripple';

@Component({
    selector: 'va-table-menu',
    template: `
    <span class="p-buttonset" style="display: flex;">
      <button
        [class]="items.length > 0 ? 'p-button-secondary' : 'p-button-secondary-single'"
        pButton
        pRipple
        type="button"
        [label]="label"
        [routerLink]="link"
      ></button>
      @if (items && items.length > 0) {
        <button
          class="p-button-menu"
          pButton
          pRipple
          type="button"
          [icon]="icon"
          (click)="menuOverlay.toggle($event)"
        ></button>
      }
      <p-menu
        #menuOverlay
        [appendTo]="'body'"
        [showTransitionOptions]="'200ms'"
        [hideTransitionOptions]="'200ms'"
        [popup]="true"
        [model]="items"
      ></p-menu>
    </span>
    `,
    standalone: true,
    imports: [
        ButtonDirective,
        ButtonModule,
        RippleModule,
        RouterLink,
        MenuModule,
    ],
  styles: [
    `
      .p-button-secondary {
        color: #ffffff;
        background: var(--primary-blue);
        border-width: 1px;
        border-style: solid;
        border-color: var(--primary-blue);
        border-image: initial;

        &:first-of-type {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
      }

      .p-button-secondary-single {
        color: #ffffff;
        background: var(--primary-blue);
        border-width: 1px;
        border-style: solid;
        border-color: var(--primary-blue);
        border-image: initial;
      }

      .p-button-menu {
        background-color: var(--secondary-menu);
        border-color: var(--secondary-menu);
        &:last-of-type {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }
    `
  ]
})
export class TableMenuComponent implements OnInit {
  private _items: MenuItem[];

  @Input() icon = 'fa fa-list';
  @Input() label: string;
  @Input() link: string[];
  @Input()
  set items(items: MenuItem[]) {
    this._items = items.filter((el) => el.visible);
  }

  get items(): MenuItem[] {
    return this._items;
  }

  constructor() {}

  ngOnInit(): void {
    console.log();
  }
}
