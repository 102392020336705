import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgStyle } from '@angular/common';
import { ButtonDirective } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { LocalizedDatePipe } from '../pipes/localized-date.pipe';
import { DatePickerModule } from 'primeng/datepicker';
import { InputGroup } from 'primeng/inputgroup';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';

@Component({
  selector: 'va-edit-input-date',
  template: `
      @if (!isEditing) {
          <span class="value-content">
        <div [ngStyle]="{ order: defaultOrder ? 1 : 2 }" class="value">
          {{ value | localizedDate }}
        </div>
        <div [ngStyle]="{ order: defaultOrder ? 2 : 1 }" class="icon">
          <button
            pButton
            pRipple
            type="button"
            [pTooltip]="placeholder | translate"
            [tooltipPosition]="'top'"
            icon="pi pi-pencil"
            class="p-button-rounded p-button-text p-button-plain icon-edit"
            (click)="onClickEdit()"
          ></button>
        </div>
      </span>
      }
      @if (isEditing) {
          <p-inputgroup>
              <p-datepicker
                [style]="{ width: '100%' }"
                [appendTo]="'body'"
                [placeholder]="placeholder | translate"
                [selectionMode]="'single'"
                [dateFormat]="'dd/MM/yyyy'"
                [readonlyInput]="false"
                [showTime]="true"
                [hourFormat]="'24'"
                [ngModel]="value"
                (ngModelChange)="onValueChange($event)"
                name="value"
              ></p-datepicker>
              @if (value !== prevValue) {
                  <p-inputgroup-addon>
                      <i class="pi pi-save icon-pointer" (click)="onClickSave()"></i>
                  </p-inputgroup-addon>
              }
              <p-inputgroup-addon>
                  <i class="pi pi-times icon-pointer" (click)="onClickCancel()"></i>
              </p-inputgroup-addon>
          </p-inputgroup>
      }
  `,
  standalone: true,
  imports: [
    InputGroupAddonModule,
    NgStyle,
    ButtonDirective,
    TooltipModule,
    DatePickerModule,
    FormsModule,
    TranslateModule,
    LocalizedDatePipe,
    InputGroup,
  ],
})
export class EditInputDateComponent implements OnInit {
  @Input() placeholder: string;
  @Input() style: any | string;
  @Input() value: Date;
  @Input() defaultOrder = true;
  @Input() isEditing = false;
  @Output()
  isEditingChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() clickSave: EventEmitter<any> = new EventEmitter<any>();
  prevValue: Date;

  constructor() {
    this.prevValue = this.value;
  }

  ngOnInit(): void {
    this.prevValue = this.value;
  }

  onClickEdit(): void {
    this.prevValue = this.value;
    this.isEditingChange.emit(true);
  }

  onValueChange(event: any): void {
    this.prevValue = event;
    this.valueChange.emit(event);
  }

  onClickSave(): void {
    this.prevValue = this.value;
    this.isEditingChange.emit(true);
    this.clickSave.emit();
  }

  onClickCancel(): void {
    this.prevValue = this.value;
    this.isEditingChange.emit(false);
  }
}
