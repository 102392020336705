import { enableProdMode, APP_INITIALIZER, Injector, importProvidersFrom } from '@angular/core';
import { environment } from './environments/environment';
import { BASE_PATH as BASE_PATH_ADMIN_API } from '@codegen/Vaimoo.Admin.API';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { ApplicationInitializerFactory } from './app/app-init';
import { TranslateService } from '@ngx-translate/core';
import {
  HTTP_INTERCEPTORS,
  JsonpInterceptor,
  provideHttpClient,
  withInterceptors,
} from '@angular/common/http';
import { HttpResponseInterceptor } from '@core/interceptors/http-response.interceptor';
import { AppRoutingModule } from './app/app-routing.module';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { CoreModule } from '@core/core.module';
import { SilentApi } from '@core/config/global.config';
import { SharedModule } from '@shared/shared.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AppComponent } from './app/app.component';
import { providePrimeNG } from 'primeng/config';
import { CustomTheme } from './styles/custom_theme';
import { progressInterceptor, provideNgProgressHttp } from 'ngx-progressbar/http';

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    provideHttpClient(withInterceptors([progressInterceptor])),
    provideNgProgressHttp({
      silentApis: SilentApi,
    }),
    importProvidersFrom(
      AppRoutingModule,
      BrowserModule,
      CoreModule,
      SharedModule,
      ServiceWorkerModule.register('ngsw-worker.js', {
        enabled: environment.serviceWorker,
        registrationStrategy: 'registerImmediately',
      }),
    ),
    {
      provide: BASE_PATH_ADMIN_API,
      useValue: environment.baseUrlAdminApi,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: ApplicationInitializerFactory,
      deps: [Injector, TranslateService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JsonpInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpResponseInterceptor,
      multi: true,
    },
    provideAnimationsAsync(),
    providePrimeNG({
      theme: {
        preset: CustomTheme,
        options: {
          darkModeSelector: false || 'none',
          cssLayer: {
            name: 'primeng',
            order: 'primeng, primeui, tailwind-base, tailwind-utilities',
          },
        },
      },
    }),
  ],
})
  // .then(() => {
  //   if ('serviceWorker' in navigator && environment.production) {
  //     navigator.serviceWorker.register('ngsw-worker.js');
  //   }
  // })
  .catch((err) => console.log(err));
