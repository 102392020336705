import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { MenuItem } from '@layout/models/menu-item.model';
import { SessionService } from '@core/services/session.service';
import { SidebarService } from '@layout/services/sidebar.service';
import { Subscription } from 'rxjs';
import { MenuService } from '@layout/services/menu.service';
import { MenuComponent } from './menu.component';
import { SidebarComponent } from './sidebar.component';
import { SectionComponent } from './section.component';
import { RouterOutlet } from '@angular/router';

@Component({
    selector: 'va-layout',
    template: `
    <div class="layout-wrapper">
      <va-menu></va-menu>
      <div class="layout-sidebar" [class.open]="menuItem" [class.close]="!menuItem">
        <va-sidebar
          [userInfo]="userInfo"
          [menuItems]="menuItems"
          (menuOnChange)="onClickMenu($event)"
        ></va-sidebar>
        <va-section [menuItem]="menuItem"></va-section>
      </div>
      <div class="layout-content" [class.open]="menuItem" [class.close]="!menuItem">
        <div class="layout-content-inner">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  `,
    standalone: true,
    imports: [
        MenuComponent,
        SidebarComponent,
        SectionComponent,
        RouterOutlet,
    ],
})
export class LayoutComponent implements OnInit, OnDestroy {
  private menuService = inject(MenuService);
  private sessionService = inject(SessionService);
  private sidebarService = inject(SidebarService);

  subscription: Subscription;
  menuItems: MenuItem[];
  menuItem: MenuItem;
  itemFirst = false;
  userInfo: any;

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);

  constructor() {}

  ngOnInit(): void {
    this.getMenuItems();
    this.getSidebarState();
    this.getUserDetails();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onClickMenu(menuItem: MenuItem): void {
    if (menuItem.Childs.length == 0) {
      this.menuItem = null;
      this.sidebarService.setState(false);
    } else {
      this.menuItem = menuItem;
      this.sidebarService.setState(true);
    }
  }

  private getUserDetails(): void {
    this.userInfo = this.sessionService.getItem('currentUser');
  }

  private getMenuItems(): void {
    this.subscription = this.menuService.getMenu().subscribe((menuItems) => {
      this.menuItems = menuItems;
    });
  }

  private getSidebarState(): void {
    this.subscription = this.sidebarService.getState().subscribe((state) => {
      if (!state) {
        this.menuItem = null;
      }
    });
  }
}
