import { Component, Input, OnInit } from '@angular/core';
import { ColumnPanel } from '@shared/interfaces/column-panel.interface';
import { ColumnTable } from '@shared/interfaces/column-table.interface';
import { Observable } from 'rxjs';
import { PanelModule } from 'primeng/panel';
import { PrimeTemplate } from 'primeng/api';
import { TooltipModule } from 'primeng/tooltip';
import { NgTemplateOutlet, AsyncPipe } from '@angular/common';
import { SkeletonModule } from 'primeng/skeleton';
import { TranslateModule } from '@ngx-translate/core';
import { OptionalValuePipe } from '../pipes/option-value.pipe';

@Component({
    selector: 'va-panel-table',
    template: ` <p-panel [header]="header | translate">
      @if (rightHeader) {
        <ng-template pTemplate="icons">
          <b>{{ (rightHeader?.label | translate) + ': ' }}</b>
          {{ rightHeader?.value | optionValuePipe }}
        </ng-template>
      }
      <ul class="panel-table">
        @if (value && columns && columns.length > 0) {
          @for (col of columns; track col) {
            @if (col.visible) {
              <li class="list-panel-table">
                <span
                  [class]="col.tooltip ? 'spacer pi pi-info-circle color-yellow' : 'spacer'"
                  [pTooltip]="col.tooltip | translate"
                  [tooltipPosition]="'top'"
                ></span>
                <label>
                  {{ col.header | translate }}
                </label>
                @if (!col.customCellTemplate) {
                  {{ col.value(getField(value, col.field)) | optionValuePipe }}
                } @else {
                  <ng-template
                *ngTemplateOutlet="
                  col.customCellTemplate;
                  context: {
                    $implicit: col.value(getField(value, col.field)) | optionValuePipe
                  }
                "
                  >
                </ng-template>
              }
              <ng-template #customCellTemplate>
                <ng-template
                *ngTemplateOutlet="
                  col.customCellTemplate;
                  context: {
                    $implicit: col.value(getField(value, col.field)) | optionValuePipe
                  }
                "
                >
              </ng-template>
            </ng-template>
          </li>
        }
      }
    } @else {
      @if (loading | async) {
        @for (s of skeleton; track s) {
          <li class="list-panel-table-skeleton">
            <p-skeleton width="100%" height="15px"></p-skeleton>
            <p-skeleton width="100%" height="15px"></p-skeleton>
          </li>
        }
      }
    }
    <ng-template #s>
      @if (loading | async) {
        @for (s of skeleton; track s) {
          <li class="list-panel-table-skeleton">
            <p-skeleton width="100%" height="15px"></p-skeleton>
            <p-skeleton width="100%" height="15px"></p-skeleton>
          </li>
        }
      }
    </ng-template>
    @if (!value && ((loading | async) === false || (loading | async) === undefined || (loading | async) === null)) {
      <li>
        <div class="panel-empty-message">
          {{ emptyMessage | translate }}
        </div>
      </li>
    }
    </ul>
    </p-panel>`,
    styles: [
        `
      :host ::ng-deep .p-panel .p-panel-content {
        padding: 1px;
      }
    `,
    ],
    standalone: true,
    imports: [
        PanelModule,
        PrimeTemplate,
        TooltipModule,
        NgTemplateOutlet,
        SkeletonModule,
        AsyncPipe,
        TranslateModule,
        OptionalValuePipe,
    ],
})
export class PanelTableComponent {
  @Input() header: string;
  @Input() rightHeader: { label: string; value: any };
  @Input() columns: ColumnPanel[];
  @Input() value: any;
  @Input() emptyMessage = 'COMMONS.PANEL.EMPTY_MESSAGE';
  @Input() loading: Observable<boolean>;

  skeleton = new Array(10);

  getField(value: any | any[], field: string): any | any[] {
    const fields = field.split('.');
    if (fields.length > 1 && value[fields[0]]) {
      return this.getField(value[fields[0]], fields.slice(1).join('.'));
    }

    return value[field];
  }
}
