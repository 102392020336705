<va-input-layout
  [filterItem]="filterItem"
  [operatorsType]="'string'"
  (operatorChange)="operatorChange($event)"
  (typeChange)="typeChange($event)"
  (resetInput)="resetInput()"
  (restoreInput)="restoreInput($event)"
>
  <ng-container input>
    <div style="width: 100%">
      <p-select
        [style]="filterItem.style"
        [appendTo]="'body'"
        [options]="filterItem.optionsDropdown.options"
        [placeholder]="filterItem.placeholder | translate"
        [ngModel]="value"
        (ngModelChange)="value = $event ? $event : null"
        [showClear]="filterItem.optionsDropdown.showClear"
        [optionLabel]="filterItem.optionsDropdown.label"
        [optionValue]="filterItem.optionsDropdown.value"
        [filter]="filterItem.optionsDropdown.filter"
        [filterBy]="filterItem.optionsDropdown.filterBy"
        name="value"
      ></p-select>
      <input [ngModel]="filterItem.key" hidden="true" name="field" />
      <input [ngModel]="operator" hidden="true" name="operator" />
      <input [ngModel]="filterItem.logic" hidden="true" name="logic" />
      <input [ngModel]="filterItem.typeInput" hidden="true" name="type" />
      <input [ngModel]="filterItem.formatValue" hidden="true" name="formatValue" />
    </div>
  </ng-container>
</va-input-layout>
