import { Pipe, PipeTransform, inject } from '@angular/core';
import { OptionalValuePipe } from './option-value.pipe';

@Pipe({
    name: 'dmsPipe',
    standalone: true,
})
export class ConvertDmsPipe implements PipeTransform {
  private optionalValuePipe = inject(OptionalValuePipe);

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);

  constructor() {}

  transform(value: any, type: 'lat' | 'lng'): any {
    if (this.isNullOrEmptyString(value)) {
      return this.optionalValuePipe.transform(value);
    }

    let absolute = Math.abs(value);

    let degrees = Math.floor(absolute);
    let minutesNotTruncated = (absolute - degrees) * 60;
    let minutes = Math.floor(minutesNotTruncated);
    let seconds = ((minutesNotTruncated - minutes) * 60).toFixed(0);
    let direction: string;

    if (type == 'lat') {
      direction = value >= 0 ? 'N' : 'S';
    }

    if (type == 'lng') {
      direction = value >= 0 ? 'E' : 'W';
    }

    return degrees + '° ' + minutes + "' " + seconds + '" ' + direction;
  }

  private isNullOrEmptyString(val: any): boolean {
    return val === null || val === '' || val === undefined;
  }
}
