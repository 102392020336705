import { Component, Input, OnInit } from '@angular/core';
import { ColumnTable } from '@shared/interfaces/column-table.interface';
import { TableModule } from 'primeng/table';
import { PrimeTemplate } from 'primeng/api';
import { NgTemplateOutlet } from '@angular/common';
import { SkeletonModule } from 'primeng/skeleton';
import { TranslateModule } from '@ngx-translate/core';
import { OptionalValuePipe } from '../../pipes/option-value.pipe';

@Component({
    selector: 'va-basic-table',
    template: `
    <p-table
      styleClass="p-datatable-striped p-datatable-sm"
      responsiveLayout="scroll"
      [value]="value"
      [columns]="columns"
      [rowHover]="true"
      >
      <ng-template pTemplate="header" let-columns>
        <tr>
          @for (col of columns; track col) {
            @if (col.sortable) {
              <th [pSortableColumn]="col.field" [hidden]="col.hidden">
                <div class="p-d-flex p-jc-between p-ai-center">
                  {{ col.header | translate }}
                  <p-sortIcon [field]="col.field"></p-sortIcon>
                </div>
              </th>
            }
            @if (!col.sortable) {
              <th [hidden]="col.hidden">
                <div class="p-d-flex p-jc-between p-ai-center">
                  {{ col.header | translate }}
                </div>
              </th>
            }
          }
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr>
          @if (!loading) {
            @for (col of columns; track col) {
              <td [hidden]="col.hidden">
                @if (!col.customCellTemplate) {
                  {{ col.value(getField(rowData, col.field)) | optionValuePipe }}
                } @else {
                  <ng-template
                  *ngTemplateOutlet="
                    col.customCellTemplate;
                    context: {
                      $implicit: col.value(getField(rowData, col.field)),
                      row: rowData
                    }
                  "
                  >
                </ng-template>
              }
              <ng-template #customCellTemplate>
                <ng-template
                  *ngTemplateOutlet="
                    col.customCellTemplate;
                    context: {
                      $implicit: col.value(getField(rowData, col.field)),
                      row: rowData
                    }
                  "
                >
              </ng-template>
            </ng-template>
          </td>
        }
      } @else {
        @for (col of columns; track col) {
          <td>
            <p-skeleton height="30px"></p-skeleton>
          </td>
        }
      }
      <ng-template #sk>
        @for (col of columns; track col) {
          <td>
            <p-skeleton height="30px"></p-skeleton>
          </td>
        }
      </ng-template>
    </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage" let-columns>
      @if (loading) {
        @for (sk of skeleton; track sk) {
          <tr>
            @for (col of columns; track col) {
              <td>
                <p-skeleton height="30px"></p-skeleton>
              </td>
            }
          </tr>
        }
      }
      <tr>
        @if (!loading) {
          <td [attr.colspan]="columns?.length + 1">
            <div class="emptymessage">
              <i class="fa fa-archive"></i>
              {{ emptyMessage | translate }}
            </div>
          </td>
        }
      </tr>
    </ng-template>
    </p-table>
    `,
    standalone: true,
    imports: [
        TableModule,
        PrimeTemplate,
        NgTemplateOutlet,
        SkeletonModule,
        TranslateModule,
        OptionalValuePipe,
    ],
})
export class BasicTableComponent implements OnInit {
  @Input() columns: ColumnTable[];
  @Input() value: any[];
  @Input() loading = false;
  @Input() emptyMessage = 'COMMONS.TABLE.EMPTY_MESSAGE';
  @Input() loadingMessage = 'COMMONS.TABLE.LOADING_MESSAGE';

  skeleton: any[];

  constructor() {}

  ngOnInit(): void {
    console.log();
  }

  getField(value: any | any[], field: string): any | any[] {
    const fields = field.split('.');
    if (fields.length > 1 && value[fields[0]]) {
      return this.getField(value[fields[0]], fields.slice(1).join('.'));
    }

    return value[field];
  }
}
