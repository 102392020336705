import { TranslateService } from '@ngx-translate/core';
import { Pipe, PipeTransform, inject } from '@angular/core';
import { OptionalValuePipe } from './option-value.pipe';

@Pipe({
    name: 'enumConverterPipe',
    standalone: true,
})
export class EnumConverterPipe implements PipeTransform {
  private translateService = inject(TranslateService);
  private optionalValuePipe = inject(OptionalValuePipe);

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);

  constructor() {}

  transform(value: string, key: string): string {
    if (this.isNullOrEmptyString(value)) {
      return this.optionalValuePipe.transform(value);
    }

    let val: string;

    switch (key) {
      case 'YES_OR_NO':
        val = this.translateService.instant(`COMMONS.ENUMS.${key}.${value}`);
        break;
      case 'NULL_OR_NOT_NULL':
        val = this.translateService.instant(`COMMONS.ENUMS.${key}.${value}`);
        break;
      case 'ACCELEROMETER_STATUS':
        val = this.translateService.instant(`COMMONS.ENUMS.${key}.${value}`);
        break;
      case 'FLASH_STATUS':
        val = this.translateService.instant(`COMMONS.ENUMS.${key}.${value}`);
        break;
      case 'ALARM_SOURCE':
        val = this.translateService.instant(`COMMONS.ENUMS.${key}.${value}`);
        break;
      case 'MCU_STATUS':
        val = this.translateService.instant(`COMMONS.ENUMS.${key}.${value}`);
        break;
      case 'BMS_STATUS':
        val = this.translateService.instant(`COMMONS.ENUMS.${key}.${value}`);
        break;
      case 'BLE_STATUS':
        val = this.translateService.instant(`COMMONS.ENUMS.${key}.${value}`);
        break;
      case 'BATTERY_LOCK_COMM_STATE':
        val = this.translateService.instant(`COMMONS.ENUMS.${key}.${value}`);
        break;
      case 'CABLE_LOCK_COMM_STATE':
        val = this.translateService.instant(`COMMONS.ENUMS.${key}.${value}`);
        break;
      case 'CAN_COMUNICATION_STATE':
        val = this.translateService.instant(`COMMONS.ENUMS.${key}.${value}`);
        break;
      case 'CHARGER_ENGINE_STATE':
        val = this.translateService.instant(`COMMONS.ENUMS.${key}.${value}`);
        break;
      case 'BATTERY_COVER_STATE':
        val = this.translateService.instant(`COMMONS.ENUMS.${key}.${value}`);
        break;
      case 'EEPROM_COMM_STATE':
        val = this.translateService.instant(`COMMONS.ENUMS.${key}.${value}`);
        break;
      case 'GPS_FIX_QUALITY':
        val = this.translateService.instant(`COMMONS.ENUMS.${key}.${value}`);
        break;
      case 'GPRS_STATUS':
        val = this.translateService.instant(`COMMONS.ENUMS.${key}.${value}`);
        break;
      case 'GPS_STATUS':
        val = this.translateService.instant(`COMMONS.ENUMS.${key}.${value}`);
        break;
      case 'PRIMARY_BATTERY_STATUS':
        val = this.translateService.instant(`COMMONS.ENUMS.${key}.${value}`);
        break;
      case 'SECONDARY_BATTERY_STATUS':
        val = this.translateService.instant(`COMMONS.ENUMS.${key}.${value}`);
        break;
      case 'OVERALL_STATE':
        val = this.translateService.instant(`COMMONS.ENUMS.${key}.${value}`);
        break;
      case 'RING_LOCK_COMM_STATE':
        val = this.translateService.instant(`COMMONS.ENUMS.${key}.${value}`);
        break;
      case 'DOCK_STATE':
        val = this.translateService.instant(`COMMONS.ENUMS.${key}.${value}`);
        break;
      case 'LOCK_STATE':
        val = this.translateService.instant(`COMMONS.ENUMS.${key}.${value}`);
        break;
      case 'BATTERY_LOCK_STATE':
        val = this.translateService.instant(`COMMONS.ENUMS.${key}.${value}`);
        break;
      case 'VEHICLE_CATEGORY':
        val = this.translateService.instant(`COMMONS.ENUMS.${key}.${value}`);
        break;
      case 'VEHICLE_MODEL':
        val = this.translateService.instant(`COMMONS.ENUMS.${key}.${value}`);
        break;
      case 'BATTERY_TYPE':
        val = this.translateService.instant(`COMMONS.ENUMS.${key}.${value}`);
        break;
      case 'OPERATORS':
        val = this.translateService.instant(`COMMONS.ENUMS.${key}.${value}`);
        break;
      case 'TYPES':
        val = this.translateService.instant(`COMMONS.ENUMS.${key}.${value}`);
        break;
      case 'OTA_DETAIL':
        val = this.translateService.instant(`COMMONS.ENUMS.${key}.${value}`);
        break;
      default:
        val = this.optionalValuePipe.transform(value);
        break;
    }

    return val;
  }

  private isNullOrEmptyString(val: any): boolean {
    return val === null || val === '' || val === undefined;
  }
}
