import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ButtonDirective, ButtonLabel, ButtonModule } from 'primeng/button';

@Component({
  selector: 'va-button',
  template: `
    @switch (type) { @case ('label-with-icon') {
    <button
      pButton
      type="submit"
      [icon]="icon"
      iconPos="right"
      [loading]="loading"
      loadingIcon="pi pi-spinner pi-spin"
      [disabled]="loading ? false : disabled"
      [class]="['va-button', class]"
      (click)="clickButton.emit($event)"
    ><span pButtonLabel>{{label}}</span></button>
    } @case ('only-icon') {
    <button
      pButton
      type="submit"
      [icon]="icon"
      iconPos="right"
      [loading]="loading"
      loadingIcon="pi pi-spinner pi-spin"
      [disabled]="loading ? false : disabled"
      [class]="['va-button-only-icon', class]"
      (click)="clickButton.emit($event)"
    ></button>
    } @case ('only-label') {
    <button
      pButton
      type="submit"
      [iconPos]="loading ? 'right' : ''"
      [loading]="loading"
      loadingIcon="pi pi-spinner pi-spin"
      [disabled]="loading ? false : disabled"
      [class]="['va-button', class]"
      (click)="clickButton.emit($event)"
    ><span pButtonLabel>{{label}}</span></button>
    } }
  `,
  standalone: true,
  imports: [ButtonDirective, ButtonLabel, ButtonModule],
})
export class ButtonComponent implements OnInit {
  @Input() type: 'label-with-icon' | 'only-label' | 'only-icon' = 'only-label';
  @Input() label = '';
  @Input() class:
    | 'va-button-primary'
    | 'va-button-secondary'
    | 'va-button-tertiary'
    | 'va-button-ghost'
    | 'va-button-filter'
    | 'va-button-filter-item' = 'va-button-primary';
  @Input() icon = 'pi';
  @Input() disabled = false;
  @Input() loading = false;

  @Output() clickButton = new EventEmitter<Event>();

  constructor() {}

  ngOnInit(): void {
    console.log();
  }
}
