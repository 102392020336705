import { Component, OnInit, Input, OnDestroy, inject } from '@angular/core';
import { ControlContainer, NgModelGroup, FormsModule } from '@angular/forms';
import { OperatorFilterEnum } from '@shared/enum/operator-filter.enum';
import { TypeFilterEnum } from '@shared/enum/type-filter.enum';
import { SliderInput } from '@shared/models/filters/input-filter-slider.class';
import { FiltersService, ResetFilter } from '@shared/services/filters.service';
import { Subscription } from 'rxjs';
import { InputLayoutComponent } from '../input-layout/input-layout.component';
import { InputTextModule } from 'primeng/inputtext';
import { SliderModule } from 'primeng/slider';
import { LowerCasePipe } from '@angular/common';

@Component({
    selector: 'va-input-slider',
    templateUrl: './input-slider.component.html',
    viewProviders: [{ provide: ControlContainer, useExisting: NgModelGroup }],
    standalone: true,
    imports: [
        InputLayoutComponent,
        InputTextModule,
        SliderModule,
        FormsModule,
        LowerCasePipe,
    ],
})
export class InputSliderComponent implements OnInit, OnDestroy {
  private filterService = inject(FiltersService);

  @Input() filterItem: SliderInput;

  value: any;
  operator: OperatorFilterEnum | OperatorFilterEnum[];
  type: TypeFilterEnum;
  subscription: Subscription;

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);

  constructor() {}

  ngOnInit(): void {
    this.type = this.filterItem.typeFilter;
    this.operator = this.type === 'Range' ? this.filterItem.operatorRange : this.filterItem.operatorSingle;

    this.getResetFilters();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  operatorChange(event: any): void {
    this.operator = event;
  }

  typeChange(event: any): void {
    this.type = event;
    this.operator = this.type === 'Range' ? this.filterItem.operatorRange : this.filterItem.operatorSingle;
  }

  resetInput(): void {
    this.value = this.type === 'Range' ? undefined : null;
    this.operator = this.type === 'Range' ? this.filterItem.operatorRange : this.filterItem.operatorSingle;
  }

  restoreInput(field: any): void {
    this.value = field.value;
  }

  get val(): string {
    if (this.value && typeof this.value === 'object' && this.value.length > 0) {
      return this.value.join(' - ');
    }

    if (this.value && typeof this.value !== 'object') {
      return this.value;
    }

    return '';
  }

  private getResetFilters(): void {
    this.subscription = this.filterService.getResetFilters().subscribe((state: ResetFilter) => {
      switch (state.type) {
        case 'restore':
          break;
        case 'resetAll':
          this.checkState(state.reset);
          break;
        case 'resetSingle':
          if (state.id === this.filterItem.key) {
            this.checkState(state.reset);
          }
          break;
      }
    });
  }

  private checkState(flag: boolean): void {
    if (flag) {
      this.value = this.type === 'Range' ? undefined : null;
    }
  }
}
