import { TranslateService } from '@ngx-translate/core';
import { Pipe, PipeTransform, inject } from '@angular/core';
import { OptionalValuePipe } from './option-value.pipe';

@Pipe({
    name: 'vehicleConnectedPipe',
    standalone: true,
})
export class VehicleConnectedPipe implements PipeTransform {
  private translateService = inject(TranslateService);
  private optionalValuePipe = inject(OptionalValuePipe);

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);

  constructor() {}

  transform(value: boolean | string): string {
    if (this.isNullOrEmptyString(value)) {
      return this.optionalValuePipe.transform(value);
    }

    return value === true || value === 'true'
      ? this.translateService.instant('COMMONS.VEHICLE_STATUS.ONLINE')
      : value === false || value === 'false'
      ? this.translateService.instant('COMMONS.VEHICLE_STATUS.OFFLINE')
      : this.optionalValuePipe.transform(value);
  }

  private isNullOrEmptyString(val: any): boolean {
    return val === null || val === '' || val === undefined;
  }
}
