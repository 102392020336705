import { ActivatedRoute } from '@angular/router';
import { LazyLoadEvent, PrimeTemplate } from 'primeng/api';
import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy,
  ViewChild,
  inject,
  ViewContainerRef,
  AfterViewInit,
  TemplateRef,
} from '@angular/core';
import { Subscription, timer } from 'rxjs';
import { SortTable } from '@shared/enum/sort.enum';
import { ColumnTable } from '@shared/interfaces/column-table.interface';
import { FilterBase } from '@shared/interfaces/filter-base.interface';
import { FilterModel } from '@shared/interfaces/filter-model.interface';
import { QueryParamsService } from '@shared/services/query-params.service';
import { FiltersUtil } from '@shared/utils/filters.util';
import { TableService } from '@shared/services/table.service';
import { FiltersService } from '@shared/services/filters.service';
import { TableModule } from 'primeng/table';
import { FiltersComponent } from '../dynamic-filter/filters.component';
import { CommonModule, NgClass, NgTemplateOutlet } from '@angular/common';
import { ButtonEventComponent } from '../button-event.component';
import { TooltipModule } from 'primeng/tooltip';
import { ColumnSettingsOverlayComponent } from './column-settings-overlay.component';
import { SkeletonModule } from 'primeng/skeleton';
import { ColumnSettingsDialogComponent } from './column-settings-dialog.component';
import { TranslateModule } from '@ngx-translate/core';
import { OptionalValuePipe } from '../../pipes/option-value.pipe';
import { Button } from 'primeng/button';

@Component({
  selector: 'va-table',
  template: `
    <p-table
      #table
      stripedRows="false"
      styleClass="p-datatable-sm"
      responsiveLayout="scroll"
      [scrollable]="value && value.length > 0 ? scrollable : false"
      [lazy]="true"
      [size]="size"
      [columns]="columns"
      [value]="value"
      [paginator]="true"
      [rowHover]="true"
      [first]="first"
      [rows]="rows"
      [sortField]="defaultSort.field"
      [sortOrder]="defaultSort.order"
      [totalRecords]="totalRecords"
      [selectionPageOnly]="true"
      [(selection)]="selectedRow"
      [rowSelectable]="rowSelectable"
      (onLazyLoad)="lazyLoadChange($event)"
      (onPage)="pageChange($event)"
      (onSort)="sortChange($event)"
      (onRowSelect)="selectedRowChange.emit(selectedRow)"
      (onRowUnselect)="selectedRowChange.emit(selectedRow)"
    >
      @if (filters && filters.length > 0) {
      <ng-template pTemplate="caption">
        <div
          [ngStyle]="
            !filterApplied
              ? {
                  display: headerButtonPosition ? 'flex' : '',
                  'justify-content': headerButtonPosition,
                  gap: '1rem'
                }
              : null
          "
        >
          <va-filters
            [filters]="filters"
            [filtersName]="filtersName"
            (filterChange)="filterChange($event)"
          ></va-filters>

          @if (detachedColumnSettings) {
          <p-button
            [style]="{ height: '40px' }"
            (click)="showDialogColumn()"
            [icon]="'pi pi-list'"
            type="label-with-icon"
            label="{{ 'COMMONS.COLUMNS.TITLE' | translate }}"
            variant="outlined"
            severity="success"
          >
          </p-button>
          }

          <ng-template #extraContainer></ng-template>
        </div>
      </ng-template>
      }
      <ng-template pTemplate="header" let-columns>
        <tr>
          @if (enableColumnSettings && !selectable && !loading) {
          <th
            [style]="{ width: '50px', maxWidth: '50px' }"
            [ngClass]="{ settings: enableColumnSettings }"
            [frozen]="true"
            [alignFrozen]="'right'"
            pFrozenColumn
          >
            @if (enableColumnSettings && columnSettingsType === 'modal') {
            <va-button-event
              [pTooltip]="'COMMONS.COLUMNS.TITLE' | translate"
              [tooltipPosition]="'top'"
              [icon]="'pi pi-cog'"
              (clickIcon)="showDialogColumn()"
            ></va-button-event>
            } @if (enableColumnSettings && columnSettingsType === 'overlay') {
            <va-column-settings-overlay
              [items]="columnsSettings"
              (itemsChange)="refreshColumns($event)"
            ></va-column-settings-overlay>
            }
          </th>
          } @if (!enableColumnSettings && selectable && !loading) {
          <th
            [style]="{ width: '50px', maxWidth: '50px' }"
            [frozen]="true"
            [alignFrozen]="'right'"
            pFrozenColumn
          >
            <p-tableHeaderCheckbox (click)="selectedRowChange.emit(selectedRow)"></p-tableHeaderCheckbox>
          </th>
          } @if (enableColumnSettings && selectable && !loading) {
          <th
            [style]="{ width: '100px', maxWidth: '100px' }"
            [frozen]="true"
            [alignFrozen]="'right'"
            pFrozenColumn
          >
            <div class="content-icon">
              @if (enableColumnSettings && columnSettingsType === 'modal') {
              <va-button-event
                [pTooltip]="'COMMONS.COLUMNS.TITLE' | translate"
                [tooltipPosition]="'top'"
                [icon]="'pi pi-cog'"
                (clickIcon)="showDialogColumn()"
              ></va-button-event>
              } @if (enableColumnSettings && columnSettingsType === 'overlay') {
              <va-column-settings-overlay
                [items]="columnsSettings"
                (itemsChange)="refreshColumns($event)"
              ></va-column-settings-overlay>
              }
              <p-tableHeaderCheckbox (click)="selectedRowChange.emit(selectedRow)"></p-tableHeaderCheckbox>
            </div>
          </th>
          } @for (col of columns; track col) {
          <th
            [style]="value && value.length > 0 ? { width: col.width, maxWidth: col.maxWidth } : null"
            [pSortableColumnDisabled]="!col.sortable"
            [pSortableColumn]="col.field"
            [hidden]="!col.visible"
            [frozen]="value && value.length > 0 ? col.frozen : false"
            [alignFrozen]="'right'"
            pFrozenColumn
          >
            <div class="p-d-flex p-jc-between p-ai-center">
              {{ col.header | translate }}
              @if (col.selected) {
              <p-sortIcon [field]="col.field"></p-sortIcon>
              }
            </div>
          </th>
          }
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns" let-rowIndex="rowIndex">
        <tr [style.background]="addBackgroundRow(rowData)">
          @if (!loading) { @if (enableColumnSettings && !selectable && !loading) {
          <td
            [style]="{ width: '50px', maxWidth: '50px' }"
            [frozen]="true"
            [alignFrozen]="'right'"
            pFrozenColumn
          ></td>
          } @if (selectable && !enableColumnSettings && !loading) {
          <td
            [style]="{ width: '50px', maxWidth: '50px' }"
            [frozen]="true"
            [alignFrozen]="'right'"
            pFrozenColumn
          >
            <p-tableCheckbox
              [value]="rowData"
              [disabled]="!rowData['needsUpdate']"
              [index]="rowIndex"
            ></p-tableCheckbox>
          </td>
          } @if (selectable && enableColumnSettings && !loading) {
          <td
            [style]="{ width: '50px', maxWidth: '50px' }"
            [frozen]="true"
            [alignFrozen]="'right'"
            pFrozenColumn
          >
            <div class="content-icon">
              <div class="fake-icon"></div>
              <p-tableCheckbox
                [value]="rowData"
                [disabled]="!rowData['needsUpdate']"
                [index]="rowIndex"
              ></p-tableCheckbox>
            </div>
          </td>
          } @for (col of columns; track col) {
          <td
            [style]="value && value.length > 0 ? { width: col.width, maxWidth: col.maxWidth } : null"
            [hidden]="!col.visible"
            [frozen]="value && value.length > 0 ? col.frozen : false"
            [pTooltip]="col.header && !col.field.isOn ? col.value(getField(rowData, col.field)) : null"
            tooltipPosition="top"
            showDelay="500"
            [alignFrozen]="'right'"
            pFrozenColumn
          >
            @if (!col.customCellTemplate) {
            {{ col.value(getField(rowData, col.field)) | optionValuePipe }}
            } @else {
            <ng-template
              *ngTemplateOutlet="
                col.customCellTemplate;
                context: {
                  $implicit: col.value(getField(rowData, col.field)),
                  row: rowData
                }
              "
            >
            </ng-template>
            }
            <ng-template #customCellTemplate>
              <ng-template
                *ngTemplateOutlet="
                  col.customCellTemplate;
                  context: {
                    $implicit: col.value(getField(rowData, col.field)),
                    row: rowData
                  }
                "
              >
              </ng-template>
            </ng-template>
          </td>
          } } @else { @for (col of columns; track col) {
          <td [style]="value && value.length > 0 ? { width: col.width, maxWidth: col.maxWidth } : null">
            @if (scrollable) {
            <p-skeleton [width]="'125px'" height="25px"></p-skeleton>
            } @if (!scrollable) {
            <p-skeleton height="25px"></p-skeleton>
            }
          </td>
          } }
          <ng-template #sk>
            @for (col of columns; track col) {
            <td [style]="value && value.length > 0 ? { width: col.width, maxWidth: col.maxWidth } : null">
              @if (scrollable) {
              <p-skeleton [width]="'125px'" height="25px"></p-skeleton>
              } @if (!scrollable) {
              <p-skeleton height="25px"></p-skeleton>
              }
            </td>
            }
          </ng-template>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage" let-columns>
        @if (loading) { @for (sk of skeleton; track sk) {
        <tr>
          @for (col of columns; track col) {
          <td [style]="value && value.length > 0 ? { width: col.width, maxWidth: col.maxWidth } : null">
            @if (scrollable) {
            <p-skeleton [width]="'125px'" height="25px"></p-skeleton>
            } @if (!scrollable) {
            <p-skeleton height="25px"></p-skeleton>
            }
          </td>
          }
        </tr>
        } }
        <tr>
          @if (!loading) {
          <td [attr.colspan]="columns.length + 1">
            <div class="emptymessage">
              <i class="fa fa-archive"></i>
              {{ emptyMessage | translate }}
            </div>
          </td>
          }
        </tr>
      </ng-template>
    </p-table>
    <va-column-settings-dialog
      [(show)]="showModal"
      [(columns)]="columnsSettings"
      (columnsChange)="refreshColumns($event)"
    ></va-column-settings-dialog>
  `,
  styles: [
    `
      .content-icon {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 5px;
      }

      .fake-icon {
        width: 32.99px;
        height: 32.99px;
      }
    `,
  ],
  providers: [QueryParamsService],
  standalone: true,
  imports: [
    TableModule,
    CommonModule,
    PrimeTemplate,
    FiltersComponent,
    NgClass,
    ButtonEventComponent,
    TooltipModule,
    ColumnSettingsOverlayComponent,
    NgTemplateOutlet,
    SkeletonModule,
    ColumnSettingsDialogComponent,
    TranslateModule,
    OptionalValuePipe,
    Button,
  ],
})
export class TableComponent implements OnInit, OnDestroy, AfterViewInit {
  private route = inject(ActivatedRoute);
  private queryParamsService = inject(QueryParamsService);
  private tableService = inject(TableService);
  private filterService = inject(FiltersService);

  @ViewChild('table', { static: true }) table: TableComponent;

  @ViewChild('extraContainer', { read: ViewContainerRef }) extraContainer!: ViewContainerRef;
  @Input() extraTemplate?: TemplateRef<any>;

  @Input() headerButtonPosition:
    | 'center'
    | 'start'
    | 'end'
    | 'flex-start'
    | 'flex-end'
    | 'left'
    | 'right'
    | 'normal'
    | 'space-between'
    | 'space-around'
    | 'space-evenly'
    | 'stretch'
    | 'safe center'
    | 'unsafe center'
    | 'inherit'
    | 'initial'
    | 'revert'
    | 'revert-layer'
    | 'unset' = null;

  @Input() scrollable = false;
  @Input() size: 'small' | 'large' | null = null;

  @Input() filtersName: string;
  @Input() filters: FilterBase[];

  @Input() defaultSort: { field: string; order: number };
  @Input() columns: ColumnTable[];
  @Input() value: any[];

  /************** INPUT COLUMN SETTINGS **************/
  @Input() enableColumnSettings = false;
  @Input() detachedColumnSettings = false;
  @Input() columnSettingsType: string | 'modal' | 'overlay' = 'overlay';

  @Input() rows: number;
  @Input() totalRecords: number;

  @Input() loading = false;
  @Input() emptyMessage = 'COMMONS.TABLE.EMPTY_MESSAGE';

  /************** INPUT ROW SELCTABLE **************/
  @Input() selectable = false;
  @Input() rowSelectable: boolean;

  @Output() lazyEvent: EventEmitter<{
    event: LazyLoadEvent;
    filters: string;
  }> = new EventEmitter<{ event: LazyLoadEvent; filters: string }>();
  @Output() filterEvent: EventEmitter<{
    event: LazyLoadEvent;
    filters: string;
  }> = new EventEmitter<{ event: LazyLoadEvent; filters: string }>();
  @Output() columnsChange: EventEmitter<ColumnTable[]> = new EventEmitter<ColumnTable[]>();
  @Output() selectedRowChange: EventEmitter<any[]> = new EventEmitter<any[]>();
  @Output() selectedSortField = new EventEmitter<String>();

  first = 0;
  sorts: Array<{ field: string; dir: string }> = [];
  page = 1;
  filterModel: FilterModel[] = [];
  showModal = false;
  skeleton: any[];
  selectedRow: any[] = [];
  subscription: Subscription;
  columnsSettings: ColumnTable[] = [];
  timeout$ = timer(0);
  defaultFilters: string;

  filterApplied: boolean = false;
  private emptyFilter =
    '{"pageSize":10,"pageIndex":1,"sort":[{"field":"visualId","dir":"asc"}],"filter":{"field":null,"operator":null,"value":null,"logic":null,"filters":[]}}';

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);

  constructor() {}

  ngOnInit(): void {
    this.columnsSettings = this.columns;
    this.refreshColumns(this.columns);
    this.skeleton = new Array(this.rows);
    this.page = Number(this.route.snapshot.queryParamMap.get('page'));
    this.first = this.page ? (this.page - 1) * this.rows : 0;
    this.page = this.first / this.rows + 1;
    this.sorts = this.queryParamsService.getQueryParamsDecoded('sort');
    this.getSortColumn(this.sorts);
    this.queryParamsService.setQueryParams('page', this.page);

    this.subscription = this.filterService.getDefaultFilters().subscribe((res) => {
      this.lazyLoadChange({ first: 0, rows: 20 } as LazyLoadEvent);
      this.defaultFilters = res;
    });

    this.subscription = this.filterEvent.subscribe((filters: FilterBase[]) => {
      if (!this.isFilterObjectEmpty(JSON.parse(filters['filters']).filter)) {
        this.filterApplied = true;
      } else {
        this.filterApplied = false;
      }
    });
  }

  private isFilterObjectEmpty(obj: any): boolean {
    return Object.values(obj).every(value =>
      value === null || (Array.isArray(value) && value.length === 0)
    );
  }

  ngAfterViewInit() {
    if (this.extraTemplate) {
      this.extraContainer.createEmbeddedView(this.extraTemplate);
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  lazyLoadChange(event: LazyLoadEvent): void {
    console.log('lazyLoadChange');
    event.first = event.first + this.rows;
    this.timeout$.subscribe(() => {
      this.page = this.first / this.rows + 1;
      this.filterModel = this.getFiltersModel();
      this.queryParamsService.setQueryParams('page', this.page);

      this.resetSelectedRow();

      this.lazyEvent.emit({
        event: event,
        filters: this.getFilterModelToString(this.filterModel, this.rows, this.page, this.sorts),
      });
    });
  }

  pageChange(event: LazyLoadEvent): void {
    this.first = event.first;
    this.page = this.first / this.rows + 1;
    this.resetSelectedRow();
    this.queryParamsService.setQueryParams('page', this.page);
  }

  sortChange(event: { field: string; order: number }): void {
    if (event.field) {
      this.sorts = [
        {
          field: event.field,
          dir: event.order < 1 ? SortTable.DESC : SortTable.ASC,
        },
      ];
      this.queryParamsService.setQueryParamsEncoded('sort', this.sorts);
      this.selectedSortField.emit(event.field);
    }
  }

  filterChange(filters: FilterModel[]): void {
    console.log('filterChange');
    this.reset();
    this.filterModel = filters;
    this.filterEvent.emit({
      event: { first: this.rows, rows: this.rows },
      filters: this.getFilterModelToString(this.filterModel, this.rows, this.page, this.sorts),
    });
  }

  getField(value: any | any[], field: string): any | any[] {
    const fields = field.split('.');
    if (fields.length > 1 && value[fields[0]]) {
      return this.getField(value[fields[0]], fields.slice(1).join('.'));
    }

    return value[field];
  }

  refreshColumns(event: ColumnTable[]): void {
    if (this.scrollable) {
      this.columns = event.filter((el: ColumnTable) => el.visible);
    }
  }

  reset(): void {
    this.first = 0;
    this.page = this.first / this.rows + 1;
    this.queryParamsService.setQueryParams('page', this.page);
  }

  addBackgroundRow(rowData: any): string {
    return this.selectedRow.includes(rowData) ? '#b3e5fc' : '';
  }

  showDialogColumn(): void {
    this.showModal = true;
  }

  private resetSelectedRow(): void {
    this.selectedRow = [];
    this.selectedRowChange.emit(this.selectedRow);
  }

  private getFilterModelToString(
    filters: FilterModel[],
    rows: number,
    page: number,
    sorts: Array<{ field: string; dir: string }>,
  ): string {
    return FiltersUtil.GetFilterModelToString(filters, rows, page, sorts);
  }

  private getFiltersModel(): FilterModel[] {
    let fs = this.queryParamsService.getQueryParamsDecoded('filters');
    return FiltersUtil.SplitRangeValue(fs);
  }

  private getSortColumn(sorts: Array<{ field: string; dir: string }>): void {
    if (sorts.length === 0 && this.defaultSort) {
      this.sorts = [
        {
          field: this.defaultSort.field,
          dir: this.defaultSort.order < 1 ? SortTable.DESC : SortTable.ASC,
        },
      ];
    }

    if (sorts.length > 0) {
      this.defaultSort = sorts.map((el) => {
        return {
          field: el.field,
          order: el.dir == SortTable.DESC ? -1 : 1,
        };
      })[0];
      this.sorts = sorts;
    }
  }
}
