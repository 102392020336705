import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { RadioButtonModule } from 'primeng/radiobutton';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import {  PopoverModule } from 'primeng/popover';

@Component({
    selector: 'va-operators-filter',
    templateUrl: './operators-filter.component.html',
    standalone: true,
    imports: [
        PopoverModule,
        RadioButtonModule,
        FormsModule,
        TranslateModule,
    ],
})
export class OperatorsFilterComponent implements OnInit {
  private _op: { label: string; key: string };

  @Input() operators: Array<{ label: string; key: string }>;

  @Input()
  set op(op: { label: string; key: string }) {
    this._op = op;
    this.selected = this.operators[this.operators.findIndex((el) => el.key === op.key)];
  }

  get op(): { label: string; key: string } {
    return this._op;
  }

  @Output() opChange: EventEmitter<{
    label: string;
    key: string;
  }> = new EventEmitter<{ label: string; key: string }>();

  overlayStyle = {
    backgroundColor: '#fff',
    boxShadow: '0px 10px 20px -10px rgba(0, 0, 0, 0.75)',
    width: 'auto',
    margin: 0,
    zIndex: '99999',
  };

  selected: { label: string; key: string };

  constructor() {}

  ngOnInit(): void {
    console.log();
  }
}
