import { Router, ActivatedRoute } from '@angular/router';
import { Injectable, inject } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class QueryParamsService {
  private router = inject(Router);
  private route = inject(ActivatedRoute);

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);

  constructor() {}

  setQueryParamsEncoded(key: string, value: any[] | any): void {
    this.router.navigate([], {
      queryParamsHandling: 'merge',
      queryParams: {
        [key]: value || (value && value.length > 0) ? this.encode(value) : null,
      },
    });
  }

  getQueryParamsDecoded(key: string): any | any[] {
    let param = this.route.snapshot.queryParamMap.get(key);
    if (param && this.decode(param) != null) {
      return this.decode(param);
    }

    return [];
  }

  setQueryParams(key: string, value: any[] | any): void {
    this.router.navigate([], {
      queryParamsHandling: 'merge',
      queryParams: {
        [key]: value,
      },
    });
  }

  getQueryParams(key: string): any | any[] {
    let param = this.route.snapshot.queryParamMap.get(key);
    if (param) {
      return JSON.parse(param);
    }

    return [];
  }

  deleteQueryParams(): void {
    this.router.navigate([], {});
  }

  private resetQueryParams(): void {
    this.router.navigate([]);
  }

  private encode(value: any | any[]): string {
    return btoa(JSON.stringify(value));
  }

  private decode(value: any | any[]) {
    let val = value.replace('%3D', '');
    try {
      return JSON.parse(atob(val));
    } catch (err) {
      this.resetQueryParams();
      val = null;
    }
  }
}
