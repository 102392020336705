import { Pipe, inject, type PipeTransform } from '@angular/core';
import { TenantService } from '@core/services/tenant.service';

@Pipe({
    name: 'tenantNamePipe',
    standalone: true
})
export class TenantNamePipe implements PipeTransform {
  mappedTenant: Array<{ label: string; value: string }> = [];

  private tenantService = inject(TenantService);

  transform(value: string, ...args: unknown[]) {

    return this.tenantService.getTenantName(value);

  }
}
