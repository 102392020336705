import { inject, Pipe, type PipeTransform } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '@core/services/user.service';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem } from 'primeng/api';
import { RolesType } from '@core/enums/roles-type.enum';

@Pipe({
  name: 'menuItemsMapMarkerClusterInfo',
  standalone: true,
})
export class MenuItemsMapMarkerClusterInfoPipe implements PipeTransform {
  private router = inject(Router);
  private translateService = inject(TranslateService);
  private userService = inject(UserService);
  private appId = inject(UserService).getAppId();

  transform(marker: any): MenuItem[] {
    return [
      {
        label: this.translateService.instant('PAGES.ASSETS.VEHICLES.TABLE_MENU.DIAGNOSTIC'),
        command: () =>
          this.router.navigate([
            'tenant',
            this.appId,
            'assets',
            'vehicles',
            'vehicle',
            'diagnostic',
            marker.id,
          ]),
        visible: true,
      },
      {
        label: this.translateService.instant('PAGES.ASSETS.VEHICLES.TABLE_MENU.ADD_REPAIR'),
        command: () =>
          this.router.navigate(['tenant', this.appId, 'maintenance', 'repairs', 'kanban'], {
            queryParams: { visualId: marker.visualId },
            queryParamsHandling: 'merge',
          }),
        visible: this.userService.getUserRoles().includes(RolesType.ADMIN_REPAIRS),
      },
    ];
  }
}
