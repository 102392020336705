import { Injectable, inject } from '@angular/core';
import { CanMatch, Route, Router, UrlSegment } from '@angular/router';
import { NotificationService } from '@core/services/notification.service';
import { UserService } from '@core/services/user.service';

@Injectable({ providedIn: 'root' })
export class NodeGuard implements CanMatch {
  private router = inject(Router);
  private userService = inject(UserService);
  private notificationService = inject(NotificationService);

  token: string;
  appId: string;

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);

  constructor() {
    this.appId = this.userService.getAppId();
  }

  canMatch(route: Route, segments: UrlSegment[]): boolean {
    let roles: Array<Array<string>> = route.data.role;
    let check = false;

    if (roles && roles.length > 0) {
      check = roles.reduce((acc, el) => {
        return acc || this.toHaveAccess(el);
      }, false);

      if (check) {
        return true;
      }

      this.getErrorAndRedirect(check);

      return false;
    } else {
      this.getErrorAndRedirect(check);

      return false;
    }
  }

  private toHaveAccess(roles: string[]): boolean {
    let isLoggedIn;

    this.userService.isLoggedIn$.subscribe((res) => {
      isLoggedIn = res;
    });

    return isLoggedIn && this.userService.checkRoles(roles);
  }

  private getErrorAndRedirect(check: boolean): void {
    this.router.navigate(['error', '401'], {
      queryParams: {
        returnUrl: window.location.pathname,
        returnSearch: window.location.search,
        canAccess: check,
      },
    });
    this.notificationService.error('COMMONS.ERROR.ROUTING_ERROR', 'COMMONS.ERROR.TITLE');
  }
}
