import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { OptionalValuePipe } from './option-value.pipe';

@Pipe({
    name: 'genericPipe',
    standalone: true,
})
export class GenericConverterPipe implements PipeTransform {
  private decimalPipe = inject(DecimalPipe);
  private optionalValuePipe = inject(OptionalValuePipe);

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);

  constructor() {}

  transform(value: any, unitCode: string, digitsInfo: string): string {
    let currentLang = 'it'; //this.translateService.currentLang

    if (this.isNullOrEmptyString(value)) {
      return this.optionalValuePipe.transform(value);
    }

    let val = this.decimalPipe.transform(value ? value : 0, digitsInfo, currentLang);

    return `${val} ${unitCode}`;
  }

  private isNullOrEmptyString(val: any): boolean {
    return val === null || val === '' || val === undefined;
  }
}
