import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform, inject } from '@angular/core';
import { DateConfig } from '@core/config/global.config';
import { TranslateService } from '@ngx-translate/core';
import { OptionalValuePipe } from './option-value.pipe';

@Pipe({
    name: 'localizedDate',
    pure: false,
    standalone: true,
})
export class LocalizedDatePipe implements PipeTransform {
  private datePipe = inject(DatePipe);
  private translateService = inject(TranslateService);
  private optionalValuePipe = inject(OptionalValuePipe);

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);

  constructor() {}

  transform(value: any, utc?: boolean): any {
    let flagDefaultUtc = utc === undefined ? false : utc;
    let currentLang = 'en'; //this.translateService.currentLang
    if (this.isNullOrEmptyString(value)) {
      return this.optionalValuePipe.transform(value);
    } else {
      switch (currentLang) {
        case 'it':
          return this.datePipe
            .transform(
              this.getValue(value, flagDefaultUtc),
              DateConfig.it,
              undefined,
              this.translateService.currentLang,
            )
            .toUpperCase();
        case 'en':
          return this.datePipe
            .transform(
              this.getValue(value, flagDefaultUtc),
              DateConfig.it,
              undefined,
              this.translateService.currentLang,
            )
            .toUpperCase();
      }
    }
  }

  private getValue(value: any, utc: boolean): number | string {
    //console.log(value);
    let date = new Date(value);
    if (utc) {
      return Date.UTC(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        date.getHours(),
        date.getMinutes(),
      );
    } else {
      return value;
    }
  }

  private isNullOrEmptyString(val: any): boolean {
    return val === null || val === '' || val === undefined;
  }
}
