import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ButtonDirective } from 'primeng/button';
import { RippleModule } from 'primeng/ripple';

@Component({
  selector: 'va-button-event',
  template: `
    <button
      [style]="customStyle"
      pButton
      pRipple
      type="button"
      [icon]="icon"
      class="p-button-rounded p-button-text p-button-plain"
      [disabled]="disabled"
      (click)="clickIcon.emit($event)"
    ></button>
  `,
  styles: [
    `
      .disabled {
        cursor: not-allowed;
      }
    `,
  ],
  standalone: true,
  imports: [ButtonDirective, RippleModule],
})
export class ButtonEventComponent implements OnInit {
  @Input() icon: string;
  @Input() customStyle: string;
  @Input() disabled = false;
  @Output() clickIcon: EventEmitter<any> = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {
    console.log();
  }
}
