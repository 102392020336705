import { Component, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FieldBase } from '@shared/models/forms/field-base.model';
import { FormControlService } from '@shared/services/form-control.service';
import { DynamicInputComponent } from './dynamic-input.component';

@Component({
    selector: 'va-dynamic-form-input',
    template: `<form class="grid" (change)="onSubmit(form.value)" [formGroup]="form">
      @for (field of inputs; track field) {
        <div class="{{ field.class }}">
          <va-dynamic-input [field]="field" [form]="form"></va-dynamic-input>
        </div>
      }
    </form>`,
    providers: [FormControlService],
    standalone: true,
    imports: [
        FormsModule,
        ReactiveFormsModule,
        DynamicInputComponent,
    ],
})
export class DynamicFormInputComponent implements OnInit {
  private formControlService = inject(FormControlService);

  private _inputs: FieldBase<string>[] = [];

  @Input()
  set inputs(inputs: FieldBase<string>[]) {
    this._inputs = inputs;
    this.form = this.formControlService.toFormGroupInput(this.inputs as FieldBase<string>[]);
  }

  get inputs(): FieldBase<string>[] {
    return this._inputs;
  }

  @Output() formEvent: EventEmitter<any> = new EventEmitter<any>();

  form: FormGroup = new FormGroup({});

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);

  constructor() {}

  ngOnInit(): void {
    console.log();
  }

  onSubmit(formValue: any): void {
    console.log(formValue);
    this.formEvent.emit(this.form.valid ? formValue : null);
  }
}
