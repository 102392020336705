import { Component, Input, OnInit } from '@angular/core';
import { PercentagePipe } from '../pipes/percentage.pipe';
import { TagModule } from 'primeng/tag';
import { IconField } from 'primeng/iconfield';
import { NgStyle } from '@angular/common';

@Component({
  selector: 'va-progress-tag',
  template: `
    <p-tag
      [ngStyle]="isRoundedBorder ? { 'border-radius': '15px' } : {}"
      [class.gray-tag]="!charge"
      [class.red-tag]="charge && charge >= 0 && charge < 25"
      [class.orange-tag]="charge && charge >= 25 && charge < 75"
      [class.green-tag]="charge && charge >= 75 && charge <= 100"
      [value]="charge | percentagePipe: 100"
    >
      @if (showIcon) {
        <p-iconfield styleClass="fa fa-battery-full"></p-iconfield>
      }
    </p-tag>
  `,
  styles: [
    `
      p-tag.red-tag {
        background-color: red;
        color: white;
      }

      p-tag.orange-tag {
        background-color: orange;
        color: black;
      }

      p-tag.green-tag {
        background-color: green;
        color: white;
      }

      p-tag.gray-tag {
        background-color: gray;
        color: white;
      }
    `,
  ],
  standalone: true,
  imports: [TagModule, IconField, PercentagePipe, NgStyle],
})
export class ProgressTagComponent implements OnInit {
  @Input() charge: number;
  @Input() showIcon: boolean;
  @Input() isRoundedBorder: boolean;

  constructor() {}

  ngOnInit(): void {
    console.log();
  }
}
