import { Component, OnInit } from '@angular/core';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

@Component({
    selector: 'va-spinner',
    template: `<div class="spinner">
    <p-progressSpinner
      [style]="{ width: '150px', height: '150px' }"
      styleClass="spinner"
      strokeWidth="8"
      fill="#047dff"
      animationDuration=".5s"
    ></p-progressSpinner>
    <div class="spinner-logo"></div>
  </div>`,
    standalone: true,
    imports: [ProgressSpinnerModule],
})
export class SpinnerComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {
    console.log();
  }
}
