import { Component, Input, OnInit } from '@angular/core';
import { NgStyle, UpperCasePipe } from '@angular/common';
@Component({
    selector: 'va-user-thumbs',
    template: `
    <div class="va-user-container"></div>
    @if (firstname && lastname) {
      <div
        class="va-user-circle"
      [ngStyle]="{
        width: size + 'px',
        height: size + 'px',
        fontSize: (size - 2) / 2 + 'px'
      }"
        >
        {{ (firstname.charAt(0) | uppercase) + (lastname.charAt(0) | uppercase) }}
      </div>
    }
    `,
    standalone: true,
    imports: [NgStyle, UpperCasePipe],
})
export class UserThumbsComponent implements OnInit {
  @Input() firstname: string;
  @Input() lastname: string;
  @Input() size = 50;

  constructor() {}

  ngOnInit(): void {
    console.log();
  }
}
