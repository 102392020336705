import { Component, Input } from '@angular/core';
import { FilterBase } from '@shared/interfaces/filter-base.interface';
import { InputTextComponent } from './inputs/input-text/input-text.component';
import { InputNumberComponent } from './inputs/input-number/input-number.component';
import { InputDateComponent } from './inputs/input-date/input-date.component';
import { InputSliderComponent } from './inputs/input-slider/input-slider.component';
import { InputDropdownComponent } from './inputs/input-dropdown/input-dropdown.component';
import { InputMultiselectionComponent } from './inputs/input-multiselection/input-multiselection.component';

@Component({
    selector: 'va-filter-input',
    template: `@if (filter) {
  @switch (filter.typeInput) {
    @case ('text') {
      <va-input-text [filterItem]="filter"></va-input-text>
    }
    @case ('number') {
      <va-input-number [filterItem]="filter"></va-input-number>
    }
    @case ('date') {
      <va-input-date [filterItem]="filter"></va-input-date>
    }
    @case ('slider') {
      <va-input-slider [filterItem]="filter"></va-input-slider>
    }
    @case ('dropdown') {
      <va-input-dropdown [filterItem]="filter"></va-input-dropdown>
    }
    @case ('multiselection') {
      <va-input-multiselection
        [filterItem]="filter"
      ></va-input-multiselection>
    }
  }
}`,
    standalone: true,
    imports: [
        InputTextComponent,
        InputNumberComponent,
        InputDateComponent,
        InputSliderComponent,
        InputDropdownComponent,
        InputMultiselectionComponent,
    ],
})
export class FilterInputComponent {
  @Input() filter!: FilterBase;

  constructor() {}
}
