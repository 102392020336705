<i class="pi pi-cog icon-pointer" (click)="panel.toggle($event)"></i>
<p-popover #panel showCloseIcon="true" [style]="overlayStyle">
  <ul class="item-ot">
    @for (type of types; track type) {
      <li class="desc">
        <p-radioButton
          [id]="type"
          [inputId]="type.key"
          [value]="type"
          [(ngModel)]="selected"
          (click)="typeChange.emit(type)"
        ></p-radioButton>
        <label [for]="type.key">{{ type.label | translate }}</label>
      </li>
    }
  </ul>
</p-popover>
