import {
  CommonModule,
  CurrencyPipe,
  DatePipe,
  DecimalPipe,
  PercentPipe,
  UpperCasePipe,
} from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { TranslateModule } from '@ngx-translate/core';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { FileSaverModule } from 'ngx-filesaver';
import { MarkdownModule } from 'ngx-markdown';
import { BadgeChargeComponent } from './components/badge/badge-charge.component';
import { BadgePowerComponent } from './components/badge/badge-power.component';
import { BadgeComponent } from './components/badge/badge.component';
import { BreadcrumbsComponent } from './components/breadcrumbs.component';
import { ButtonEventComponent } from './components/button-event.component';
import { ButtonComponent } from './components/button.component';
import { DateCardComponent } from './components/date-card.component';
import { FilterDialogComponent } from './components/dynamic-filter/filter-dialog.component';
import { FilterInputComponent } from './components/dynamic-filter/filter-input.component';
import { FiltersComponent } from './components/dynamic-filter/filters.component';
import { InputDateComponent } from './components/dynamic-filter/inputs/input-date/input-date.component';
import { InputDropdownComponent } from './components/dynamic-filter/inputs/input-dropdown/input-dropdown.component';
import { InputLayoutComponent } from './components/dynamic-filter/inputs/input-layout/input-layout.component';
import { InputMultiselectionComponent } from './components/dynamic-filter/inputs/input-multiselection/input-multiselection.component';
import { InputNumberComponent } from './components/dynamic-filter/inputs/input-number/input-number.component';
import { InputSliderComponent } from './components/dynamic-filter/inputs/input-slider/input-slider.component';
import { InputTextComponent } from './components/dynamic-filter/inputs/input-text/input-text.component';
import { OperatorsFilterComponent } from './components/dynamic-filter/inputs/operators-filter/operators-filter.component';
import { TypesFilterComponent } from './components/dynamic-filter/inputs/types-filter/types-filter.component';
import { DynamicFormInputComponent } from './components/dynamic-form/dynamic-form-input.component';
import { DynamicInputComponent } from './components/dynamic-form/dynamic-input.component';
import { EditInputDateComponent } from './components/edit-input-date.component';
import { EditInputValueComponent } from './components/edit-input-value.component';
import { EmptyLayoutComponent } from './components/empty-layout.component';
import { FieldsetTableComponent } from './components/fieldset-table.component';
import { HeaderComponent } from './components/header.component';
import { MapFilterComponent } from './components/map/map-filter.component';
import { MapMarkerClusterInfoComponent } from './components/map/map-marker-cluster-info.component';
import { MapMarkerInfoComponent } from './components/map/map-marker-info.component';
import { MapComponent } from './components/map/map.component';
import { NotificationComponent } from './components/notification.component';
import { PanelListComponent } from './components/panel-list.component';
import { PanelTableComponent } from './components/panel-table.component';
import { ProgressBarComponent } from './components/progress-bar.component';
import { SpinnerComponent } from './components/spinner.component';
import { SplashScreenComponent } from './components/splash-screen.component';
import { BasicTableComponent } from './components/table/basic-table.component';
import { ColumnSettingsDialogComponent } from './components/table/column-settings-dialog.component';
import { ColumnSettingsOverlayComponent } from './components/table/column-settings-overlay.component';
import { TableMenuComponent } from './components/table/table-menu.components';
import { TableComponent } from './components/table/table.component';
import { TooggleComponent } from './components/toggle.component';
import { UserThumbsComponent } from './components/user-thumbs.component';
import { VersionComponent } from './components/version.component';

import { BooleanConverterPipe } from './pipes/boolean-converter.pipe';
import { ConvertDmsPipe } from './pipes/convert-dms.pipe';
import { DynamicPipe } from './pipes/dynamic-pipe.pipe';
import { EnumConverterPipe } from './pipes/enum-converter.pipe';
import { FractionPipe } from './pipes/fraction.pipe';
import { GenericConverterPipe } from './pipes/generic-converter.pipe';
import { LocalizedDatePipe } from './pipes/localized-date.pipe';
import { OptionalValuePipe } from './pipes/option-value.pipe';
import { PercentagePipe } from './pipes/percentage.pipe';
import { StateConverterPipe } from './pipes/state-converter.pipe';
import { VehiclePowerPipe } from './pipes/vehcle-power.pipe';
import { VehicleCategoryConverterPipe } from './pipes/vehicle-category-converter.pipe';
import { VehicleConnectedPipe } from './pipes/vehicle-connected.pipe';
import { VehicleModelConverterPipe } from './pipes/vehicle-model-converter.pipe';
import {
  provideHttpClient,
  withInterceptorsFromDi,
  withJsonpSupport,
} from '@angular/common/http';
import { TenantNamePipe } from './pipes/tenant-name.pipe';

export const COMPONENTS = [
  SplashScreenComponent,
  SpinnerComponent,
  NotificationComponent,
  ButtonComponent,
  TooggleComponent,
  UserThumbsComponent,
  FiltersComponent,
  MapComponent,
  MapFilterComponent,
  EmptyLayoutComponent,
  MapMarkerInfoComponent,
  MapMarkerClusterInfoComponent,
  HeaderComponent,
  FilterDialogComponent,
  FilterInputComponent,
  DynamicInputComponent,
  DynamicFormInputComponent,
  TableComponent,
  BasicTableComponent,
  ColumnSettingsOverlayComponent,
  ColumnSettingsDialogComponent,
  TableMenuComponent,
  PanelTableComponent,
  FieldsetTableComponent,
  BadgeChargeComponent,
  BadgePowerComponent,
  ButtonEventComponent,
  PanelListComponent,
  BadgeComponent,
  InputDateComponent,
  InputSliderComponent,
  InputTextComponent,
  InputDropdownComponent,
  InputMultiselectionComponent,
  InputNumberComponent,
  OperatorsFilterComponent,
  InputLayoutComponent,
  TypesFilterComponent,
  VersionComponent,
  ProgressBarComponent,
  EditInputValueComponent,
  EditInputDateComponent,
  BreadcrumbsComponent,
  DateCardComponent,
];

export const DIRECTIVES = [];

export const PIPES = [
  BooleanConverterPipe,
  OptionalValuePipe,
  FractionPipe,
  VehiclePowerPipe,
  PercentagePipe,
  DynamicPipe,
  EnumConverterPipe,
  VehicleCategoryConverterPipe,
  VehicleConnectedPipe,
  VehicleModelConverterPipe,
  GenericConverterPipe,
  StateConverterPipe,
  ConvertDmsPipe,
  LocalizedDatePipe,
  TenantNamePipe,
];

export const SERVICES = [];

@NgModule({
    exports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    GoogleMapsModule,
    MarkdownModule,
    TranslateModule,
    FileSaverModule,
    CalendarModule,
    ...COMPONENTS,
    ...DIRECTIVES,
    ...PIPES,
],
    imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    GoogleMapsModule,
    MarkdownModule,
    TranslateModule,
    FileSaverModule,
    ...COMPONENTS, ...DIRECTIVES, ...PIPES,
],
    providers: [
        CurrencyPipe,
        DatePipe,
        DecimalPipe,
        PercentPipe,
        UpperCasePipe,
        ...PIPES,
        ...SERVICES,
        provideHttpClient(withInterceptorsFromDi(), withJsonpSupport()),
    ],
})
export class SharedModule {}
