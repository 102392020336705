import { Injectable, inject } from '@angular/core';
import { GlobalConfig, StorageKey } from '@core/config/global.config';
import { Config } from '@core/interfaces/config.interface';
import { BehaviorSubject, Observable } from 'rxjs';
import { SessionService } from './session.service';

@Injectable()
export class ConfigService {
  private sessionService = inject(SessionService);

  private configUpdate: BehaviorSubject<Config>;

  config: Config = GlobalConfig;

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);

  constructor() {
    if (this.sessionService.getItem(StorageKey.config)) {
      this.config = this.sessionService.getItem(StorageKey.config);
      this.configUpdate = new BehaviorSubject<Config>(this.config);
    } else {
      this.sessionService.setItem(StorageKey.config, this.config);
      this.configUpdate = new BehaviorSubject<Config>(this.config);
    }
  }

  updateConfig(config: Config): void {
    this.config = config;
    this.sessionService.setItem(StorageKey.config, this.config);
    this.configUpdate.next(config);
  }

  getConfig(): Observable<Config> {
    return this.configUpdate.asObservable();
  }
}
