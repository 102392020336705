import { TranslateService } from '@ngx-translate/core';
import { Pipe, PipeTransform, inject } from '@angular/core';
import { OptionalValuePipe } from './option-value.pipe';
import { OtaResult } from '@codegen/Vaimoo.Admin.API';

@Pipe({
    name: 'booleanPipe',
    standalone: true,
})
export class BooleanConverterPipe implements PipeTransform {
  private translateService = inject(TranslateService);
  private optionalValuePipe = inject(OptionalValuePipe);

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);

  constructor() { }

  transform(value: boolean, key?: string): string {
    if (this.isNullOrEmptyString(value)) {
      return this.optionalValuePipe.transform(value);
    }

    switch (key) {
      case 'KO_OK':
        if (value.toString() == OtaResult.Failed) {
          return this.translateService.instant(`COMMONS.BOOLEAN.KO`)
        }
        if (value.toString() == OtaResult.Succeded) {
          return this.translateService.instant(`COMMONS.BOOLEAN.OK`)
        }
        return  '-';

      default:
        return value
          ? this.translateService.instant(`COMMONS.BOOLEAN.YES`)
          : this.translateService.instant(`COMMONS.BOOLEAN.NO`);
    }
  }

  private isNullOrEmptyString(val: any): boolean {
    return val === null || val === '' || val === undefined;
  }
}
