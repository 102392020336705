import { Component, Input, OnInit } from '@angular/core';
import { VehicleTrackingMap } from '@shared/interfaces/vehicle-tracking-map.interface';
import { environment } from 'src/environments/environment';
import { RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { TagModule } from 'primeng/tag';
import { ProgressTagComponent } from '@shared/components/progress-tag.component';
import { ButtonModule } from 'primeng/button';

@Component({
  selector: 'va-map-marker-info',
  template: `
    <div class="marker-info">
      @if (infoContent) {
      <div class="marker-info-body">
        <div class="marker-info-section-head">
          <div class="marker-info-title">
            {{ infoContent?.visualId }}
          </div>
          <div class="marker-info-tags gap-2 py-1">
            @if (infoContent?.vehicleCategory) {
            <p-tag [style]="backgroundColor">{{ infoContent?.vehicleCategory }}</p-tag>
            } @if (infoContent?.vehicleModel) {
            <p-tag [style]="backgroundColor">{{ infoContent?.vehicleModel }}</p-tag>
            } @if (infoContent?.vehicleModel) {
            <va-progress-tag [charge]="infoContent?.stateOfCharge" [showIcon]="true"/>
            }
          </div>
        </div>
        @if (infoContent?.fleet) {
        <div class="marker-info-section">
          <div class="marker-info-title">
            <i class="pi pi-map-marker mr-1" [style]="pinColor"></i>
            {{ infoContent?.fleet }}
          </div>
        </div>
        } @if (infoContent?.address) {
        <div class="marker-info-section">
          <div class="marker-info-title">
            {{ 'PAGES.ASSETS.VEHICLES.MAP.ACTUAL_POSITION' | translate }}
          </div>
          <div>{{ infoContent?.address }}</div>
        </div>
        } @if (infoContent?.isLinked) {
        <div class="marker-info-section-footer">
          <div class="marker-info-details p-button p-button-outlined">
            <a [routerLink]="['vehicle', 'details', infoContent?.id]">
              {{ 'PAGES.ASSETS.VEHICLES.MAP.SHOW_DETAILS' | translate }}
            </a>
          </div>
        </div>
        }
      </div>
      }
    </div>
  `,
  standalone: true,
  imports: [ProgressTagComponent, RouterLink, TranslateModule, TagModule, ButtonModule],
})
export class MapMarkerInfoComponent implements OnInit {
  backgroundColor = `background: var(--primary-blue)`;
  pinColor = `color: var(--primary-blue)`;

  private _infoContent: VehicleTrackingMap;

  @Input()
  set infoContent(infoContent: VehicleTrackingMap) {
    let latlng = new google.maps.LatLng(infoContent?.latitude, infoContent?.longitude);
    let geocoder = new google.maps.Geocoder();
    geocoder.geocode({ location: latlng }, (res, status) => {
      if (status === google.maps.GeocoderStatus.OK) {
        infoContent.address = res[1].formatted_address;
      }

      this._infoContent = infoContent;
    });
  }

  get infoContent(): VehicleTrackingMap {
    return this._infoContent;
  }

  constructor() {}

  ngOnInit(): void {
    console.log();
  }

  // not used anymore
  //   getImage(model: string, category: string): string {
  //     let srcImg = '';
  //     switch (model) {
  //       case 'Forte V2':
  //       case 'Forte V1':
  //       case 'Forte Muscular':
  //         srcImg = '/assets/map/img/forte_dock.png';
  //         return srcImg;
  //       case 'FreeNoCable':
  //         switch(category)  {
  //           case 'E-Bike':
  //             srcImg = '/assets/map/img/libera_vaimoo.png';
  //           case 'Bike':
  //             srcImg = '/assets/map/img/libera_red.png';
  //             return srcImg;
  //         }
  //       case 'Free Cable':
  //       case 'Free 7 Marce':
  //         srcImg = '/assets/map/img/libera_vaimoo.png';
  //         return srcImg;
  //       case 'Muscular':
  //         srcImg = '/assets/map/img/libera_vaimoo.png';
  //         return srcImg;
  //       default:
  //         return environment.bikeImageFallback;
  //     }
  //   }
}
