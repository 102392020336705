import { Component, OnInit, inject } from '@angular/core';
import { SystemService } from '@codegen/Vaimoo.Admin.API';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'va-version',
    template: `<div><b>FE:</b> {{ feVersion }} <b>BE:</b> {{ beVersion }}</div>`,
    standalone: true,
})
export class VersionComponent implements OnInit {
  private systemService = inject(SystemService);

  feVersion = '';
  beVersion = '';

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);

  constructor() {
    this.systemService.pingGet().subscribe((res) => {
      this.feVersion = `v${environment.buildNumber}`;
      this.beVersion = `v${res.version}`;
    });
  }

  ngOnInit(): void {
    console.log();
  }
}
