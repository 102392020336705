import { HttpClient, provideHttpClient, withInterceptorsFromDi, withJsonpSupport } from '@angular/common/http';
import { NgModule, SecurityContext } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MessageService, ConfirmationService } from 'primeng/api';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpLoaderFactory } from '../app-init';
import { GoogleMapsModule } from '@angular/google-maps';
import { ErrorHandlerService } from './services/error-handler.service';
import { LoaderService } from './services/loader.service';
import { NotificationService } from './services/notification.service';
import { SessionService } from './services/session.service';
import { UserService } from './services/user.service';
import { ConfigService } from './services/config.service';
import { MarkdownModule } from 'ngx-markdown';

import localIt from '@angular/common/locales/it';
import localeEnGb from '@angular/common/locales/en-GB';
import { TenantService } from './services/tenant.service';

registerLocaleData(localIt, 'it');
registerLocaleData(localeEnGb, 'en');

export const SERVICES = [
  MessageService,
  ConfirmationService,
  ConfigService,
  ErrorHandlerService,
  LoaderService,
  NotificationService,
  SessionService,
  UserService,
  TenantService
];

/**
 * Core module
 */
@NgModule({ exports: [MarkdownModule], imports: [CommonModule,
        FormsModule,
        ReactiveFormsModule,
        GoogleMapsModule,
        MarkdownModule.forRoot({
            loader: HttpClient,
            sanitize: SecurityContext.NONE,
        }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        })], providers: [...SERVICES, provideHttpClient(withInterceptorsFromDi(), withJsonpSupport())] })
export class CoreModule {}
