import { Component, Input, OnInit } from '@angular/core';
@Component({
    selector: 'va-date-card',
    template: `
    <div class="date-card">
      <div class="day">{{ day }}</div>
      <div>
        <div class="month">{{ month }}</div>
        <div class="year">{{ year }}</div>
      </div>
    </div>
  `,
    styles: [
        `
      .date-card {
        border: 1px solid #ddd;
        width: 50px;
        padding: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .date-card .day {
        font-size: 48px;
        margin: 0px 10px;
        color: #2ab6b6;
      }

      .date-card .month {
        font-weight: bold;
      }

      .date-card .day {
        font-size: 48px;
        margin: 0px 10px;
        color: #2ab6b6;
      }

      .date-card .month {
        font-weight: bold;
      }
    `,
    ],
    standalone: true,
})
export class DateCardComponent implements OnInit {
  private _date: string;

  @Input()
  set date(date: string) {
    this._date = date;
    this.day = new Date(date).getDay();
    this.month = new Date(date).getMonth().toString();
    this.year = new Date(date).getFullYear();
  }

  get date(): string {
    return this._date;
  }

  day: number;
  month: string;
  year: number;

  constructor() {}

  ngOnInit(): void {
    console.log();
  }
}
