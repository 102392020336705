import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from './notification.service';
import { UserService } from './user.service';

@Injectable()
export class ErrorHandlerService implements ErrorHandler {
  private router = inject(Router);
  private userService = inject(UserService);
  private notificationService = inject(NotificationService);
  private translateService = inject(TranslateService);

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);

  constructor() {}

  handleError(err: HttpErrorResponse | any): void {

    let errorMessage: string;
    let errorTitle: string;

    if (err.error instanceof ErrorEvent) {
      errorTitle = 'Error';
      errorMessage = this.translateService.instant('COMMONS.ERROR.EVENT', err.error.message);
    } else {
      switch (err.status) {
        case 400:
          errorTitle = 'Error 400';
          errorMessage = this.getErrorMessage(err);
          this.notificationService.error(errorMessage, errorTitle);

          throw new HttpErrorResponse(err);
        case 401:
          console.log('401');
          errorTitle = 'Error 401';
          errorMessage = this.getErrorMessage(err);
          this.notificationService.error(errorMessage, errorTitle);
          this.redirect();

          throw new HttpErrorResponse(err);
        case 403:
          errorTitle = 'Error 403';
          errorMessage = this.getErrorMessage(err);
          this.notificationService.error(errorMessage, errorTitle);

          throw new HttpErrorResponse(err);
        case 404:
          errorTitle = 'Error 404';
          errorMessage = this.getErrorMessage(err);
          this.notificationService.error(errorMessage, errorTitle);

          throw new HttpErrorResponse(err);
        case 412:
          errorTitle = 'Error 412';
          errorMessage = this.getErrorMessage(err);
          this.notificationService.error(errorMessage, errorTitle);

          throw new HttpErrorResponse(err);
        case 500:
          errorTitle = 'Error 500';
          errorMessage = this.getErrorMessage(err);
          this.notificationService.error(errorMessage, errorTitle);

          throw new HttpErrorResponse(err);
        case 503:
          errorTitle = 'Error 503';
          errorMessage = this.getErrorMessage(err);
          this.notificationService.error(errorMessage, errorTitle);

          throw new HttpErrorResponse(err);
        default:
          if (err.body?.ResponseStatus) {
            errorTitle = this.getErrors(err).errorTitle;
            errorMessage = this.getErrors(err).errorMessage;
            this.notificationService.error(errorMessage, errorTitle);
          } else {
            if (err.message) {
              errorTitle = 'Error';
              errorMessage = err.message;
              this.notificationService.error(errorMessage, errorTitle);
            }
          }

          throw new HttpErrorResponse(err);
      }
    }
  }

  private getErrorMessage(err: any): string {
    return err.error?.ResponseStatus?.Message
      ? err.error?.ResponseStatus?.Message
      : this.translateService.instant(`COMMONS.ERROR.${err.status}`, {
          status: err.status,
        });
  }

  private getErrors(err: any): { errorTitle: string; errorMessage: string } {
    let errorTitle: string;
    let errorMessage: string;

    if (err.body.ResponseStatus?.Message) {
      errorTitle = `Error ${err.body.StatusCode}`;
      errorMessage = err.body.ResponseStatus?.Message;
    } else {
      errorTitle = `Error`;
      if (err.body.ResponseStatus?.Errors.lenght > 1) {
        err.body.ResponseStatus?.Errors.forEach((e: any) => {
          errorMessage += e.Message;
        });
      } else {
        errorMessage = err.body.ResponseStatus?.Errors[0].Message;
      }
    }

    return { errorTitle, errorMessage };
  }

  private redirect() {
    this.router.navigate(['auth', 'tenant', this.userService.getAppId()]);
    this.userService.logout();
  }
}
