import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, inject } from '@angular/core';
import { ControlContainer, NgModelGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { OperatorFilterEnum } from '@shared/enum/operator-filter.enum';
import { TypeFilterEnum } from '@shared/enum/type-filter.enum';
import { FilterBase } from '@shared/interfaces/filter-base.interface';
import { FilterModel } from '@shared/interfaces/filter-model.interface';
import { FiltersService } from '@shared/services/filters.service';
import { QueryParamsService } from '@shared/services/query-params.service';
import { FiltersUtil } from '@shared/utils/filters.util';
import { Subscription } from 'rxjs';
import { OperatorsFilterComponent } from '../operators-filter/operators-filter.component';
import { TypesFilterComponent } from '../types-filter/types-filter.component';
import { TranslateModule } from '@ngx-translate/core';
import { EnumConverterPipe } from '@shared/pipes/enum-converter.pipe';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';
import { InputGroupModule } from 'primeng/inputgroup';

@Component({
    selector: 'va-input-layout',
    templateUrl: './input-layout.component.html',
    viewProviders: [{ provide: ControlContainer, useExisting: NgModelGroup }],
    standalone: true,
    imports: [
        OperatorsFilterComponent,
        TypesFilterComponent,
        TranslateModule,
        EnumConverterPipe,
        InputGroupAddonModule,
        InputGroupModule
    ],
})
export class InputLayoutComponent implements OnInit, OnDestroy {
  private activatedRoute = inject(ActivatedRoute);
  private filtersService = inject(FiltersService);
  private queryParamsService = inject(QueryParamsService);

  @Input() filterItem: FilterBase;
  @Input() operatorsType: 'string' | 'number';
  @Output() operatorChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() typeChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() resetInput: EventEmitter<any> = new EventEmitter<any>();
  @Output() restoreInput: EventEmitter<any> = new EventEmitter<any>();

  range: TypeFilterEnum = TypeFilterEnum.RANGE;
  single: TypeFilterEnum = TypeFilterEnum.SINGLE;

  operatorRange: OperatorFilterEnum[];
  operatorSingle: { label: string; key: string };

  operators: { label: string; key: string }[];
  type: { label: string; key: string };

  subscription: Subscription;
  filtersAdvanced = false;

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);

  constructor() {}

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit(): void {
    this.operators = this.getOperators(this.operatorsType);
    this.subscription = this.filtersService.getAdvancedFilter().subscribe((res) => {
      this.filtersAdvanced = res;
      this.type = FiltersUtil.getType(this.filterItem.typeFilter);
      this.operatorRange = this.filterItem.operatorRange;
      this.operatorSingle = this.operatorSingle = this.operators.find(
        (op) => op.key === this.filterItem.operatorSingle,
      );
      this.getFiltersSelected();
    });
  }

  operatorOnChange(event: { label: string; key: string }): void {
    this.operatorSingle = event;
    this.operatorChange.emit(event.key);
  }

  typeOnChange(event: { label: string; key: string }): void {
    this.type = event;
    this.resetInput.emit();
    this.typeChange.emit(event.key);
  }

  private getFiltersSelected(): void {
    this.activatedRoute.queryParams.subscribe((res) => {
      let filtersSelected = this.queryParamsService.getQueryParamsDecoded('filters');

      if (filtersSelected.length > 0) {
        let filterWithSameField: FilterModel = filtersSelected.find(
          (el: FilterModel) => el.field === this.filterItem.key,
        );

        if (filterWithSameField) {
          this.restoreInput.emit(filterWithSameField);
          switch (typeof filterWithSameField.operator) {
            case 'string':
              this.operatorSingle = this.operatorSingle = this.operators.find(
                (op) => op.key === filterWithSameField.operator,
              );
              this.operatorChange.emit(filterWithSameField.operator);
              this.type = { label: 'COMMONS.TYPES.SINGLE', key: this.single };
              this.typeChange.emit(this.single);
              break;
            case 'object':
              this.type = { label: 'COMMONS.TYPES.RANGE', key: this.range };
              this.typeChange.emit(this.range);
              this.operatorChange.emit([OperatorFilterEnum.GTE, OperatorFilterEnum.LTE]);
              break;
            default:
              break;
          }
        } else {
          this.typeOnChange(this.type);
        }
      } else {
        if (this.filterItem.value) {
          this.restoreInput.emit(this.filterItem);
        } else {
          this.typeOnChange(this.type);
        }
      }
    });
  }

  private getOperators(type: 'string' | 'number'): any[] {
    return FiltersUtil.getOperators(type);
  }
}
