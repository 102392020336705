import { Component, Input, OnInit } from '@angular/core';
import { ToggleSwitch } from 'primeng/toggleswitch';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'va-toggle',
    template: `<p-toggleSwitch [(ngModel)]="checked" [disabled]="disabled"></p-toggleSwitch>`,
    standalone: true,
    imports: [ToggleSwitch, FormsModule],
})
export class TooggleComponent implements OnInit {
  @Input() checked = false;
  @Input() disabled = false;

  constructor() {}

  ngOnInit(): void {
    console.log();
  }
}
