import { Component, Input, OnDestroy, OnInit, inject } from '@angular/core';
import { SplashAnimationType } from '@shared/enum/splash-animation-type.enum';
import { SplashScreenService } from '@shared/services/splash-screen.service';
import { combineLatest, Subscription, timer } from 'rxjs';
import { NgStyle } from '@angular/common';

@Component({
    selector: 'va-splash-screen',
    template: ` @if (showSplash) {
  <div
    class="splash-screen"
    [ngStyle]="{
      left: windowWidth,
      opacity: opacityChange,
      transition: splashTransition
    }"
    >
    <div class="splash-inner">
      <div class="logo"></div>
      <div class="label"></div>
      <div class="loader"></div>
    </div>
  </div>
}`,
    standalone: true,
    imports: [NgStyle],
})
export class SplashScreenComponent implements OnInit, OnDestroy {
  private splashScreenService = inject(SplashScreenService);

  @Input() animationDuration = 2;
  @Input() duration = 1;
  @Input() animationType: SplashAnimationType = SplashAnimationType.FadeOut;

  subscription: Subscription;
  windowWidth: string;
  showSplash = true;
  splashTransition: string;
  opacityChange = 1;

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);

  constructor() {}

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit(): void {
    this.subscription = combineLatest([
      this.splashScreenService.getAnimationDuration(),
      this.splashScreenService.getDuration(),
      this.splashScreenService.getAnimationType(),
    ]).subscribe((res) => {
      this.animationDuration = res[0];
      this.duration = res[1];
      this.animationType = res[2];
    });

    this.splashScreenService.setAnimationDuration(this.animationDuration);
    this.splashScreenService.setDuration(this.duration);
    this.splashScreenService.setAnimationType(this.animationType);

    timer(this.duration * 1000).subscribe(() => {
      let transitionStyle = '';
      switch (this.animationType) {
        case SplashAnimationType.SlideLeft:
          this.windowWidth = '-' + window.innerWidth + 'px';
          transitionStyle = 'left ' + this.animationDuration + 's';
          break;
        case SplashAnimationType.SlideRight:
          this.windowWidth = window.innerWidth + 'px';
          transitionStyle = 'left ' + this.animationDuration + 's';
          break;
        case SplashAnimationType.FadeOut:
          transitionStyle = 'opacity ' + this.animationDuration + 's';
          this.opacityChange = 0;
      }

      this.splashTransition = transitionStyle;
      timer(this.animationDuration * 1000).subscribe(() => {
        this.showSplash = !this.showSplash;
      });
    });
  }
}
