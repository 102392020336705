import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { ConfigService } from '@core/services/config.service';
import { HeaderService } from '@layout/services/header.service';
import { SidebarService } from '@layout/services/sidebar.service';
import { Subscription } from 'rxjs';
import { ButtonEventComponent } from './button-event.component';
import { BreadcrumbsComponent } from './breadcrumbs.component';
import { TranslateModule } from '@ngx-translate/core';
@Component({
    selector: 'va-header',
    template: `
    <div class="layout-header-container">
      <div class="layout-header">
        @if (true) {
          <div class="menu">
            <va-button-event [icon]="'pi pi-bars toogle'" (clickIcon)="openMenu()"></va-button-event>
          </div>
        }
        <div class="header-left left">
          <h4>
            {{ title | translate }}
          </h4>
          <ng-content select="[header-left]"></ng-content>
        </div>
        <div class="header-right right">
          <ng-content select="[header-right]"></ng-content>
        </div>
      </div>
      <div class="header-footer">
        <ng-content select="[header-footer]"></ng-content>
      </div>
      <va-breadcrumbs [enabled]="enabled"></va-breadcrumbs>
    </div>
    `,
    standalone: true,
    imports: [
        ButtonEventComponent,
        BreadcrumbsComponent,
        TranslateModule,
    ],
})
export class HeaderComponent implements OnInit, OnDestroy {
  private headerService = inject(HeaderService);
  private configService = inject(ConfigService);
  private sidebarService = inject(SidebarService);

  title: string;
  enabled = true;
  subscription: Subscription;

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);

  constructor() {
    this.subscription = this.configService.getConfig().subscribe((config) => {
      this.enabled = config.breadcrumbs;
    });
  }

  ngOnInit(): void {
    this.getTitle();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  openMenu() {
    this.sidebarService.toggleSidebarMobile();
  }

  private getTitle(): void {
    this.headerService.title.subscribe((updatedTitle) => {
      this.title = updatedTitle;
    });
  }
}
