import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { NgStyle } from '@angular/common';

@Component({
    selector: 'va-badge-power',
    template: `
  <span
    class="badge-power"
    [ngStyle]="{
        'background-color': power ? (customColor?.PositiveColor || 'green') : (customColor?.NegativeColor || 'grey')
      }"
  >
  </span>`,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgStyle]
})
export class BadgePowerComponent implements OnInit {
  @Input() power: boolean;
  @Input() customColor: {
    PositiveColor: string,
    NegativeColor: string,
  };

  constructor() {}

  ngOnInit(): void {
    console.log();
  }
}
