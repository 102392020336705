import { Pipe, PipeTransform, inject } from '@angular/core';
import { OptionalValuePipe } from './option-value.pipe';

@Pipe({
    name: 'fractionPipe',
    standalone: true,
})
export class FractionPipe implements PipeTransform {
  private optionalValuePipe = inject(OptionalValuePipe);

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);

  constructor() {}

  transform(dividend: any, divider: number): any {
    if (this.isNullOrEmptyString(dividend)) {
      return this.optionalValuePipe.transform(dividend);
    }

    return Number(dividend / divider);
  }

  private isNullOrEmptyString(val: any): boolean {
    return val === null || val === '' || val === undefined;
  }
}
