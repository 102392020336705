import { Component, Input, OnInit } from '@angular/core';
import { NgClass } from '@angular/common';

@Component({
    selector: 'va-badge',
    template: `<span class="badge" [ngClass]="color">{{ value }}</span>`,
    standalone: true,
    imports: [NgClass],
})
export class BadgeComponent implements OnInit {
  @Input() value: any;
  @Input() color: 'blue' | 'green' | 'red' | 'yellow';

  constructor() {}

  ngOnInit(): void {
    console.log();
  }
}
