import { Injectable, OnDestroy, inject } from '@angular/core';
import { RoleBuilder } from '@core/enums/roles-type.enum';
import { ConfigService } from '@core/services/config.service';
import { UserService } from '@core/services/user.service';
import { MenuItem } from '@layout/models/menu-item.model';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';

@Injectable({
  providedIn: "root"
})
export class MenuService implements OnDestroy {
  private userService = inject(UserService);
  private configService = inject(ConfigService);

  private menuItem$: BehaviorSubject<MenuItem[]>;
  subscription: Subscription;

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);

  constructor() {
    this.menuItem$ = new BehaviorSubject<MenuItem[]>(this.setMenu());
    this.getConfig();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  getMenu(): Observable<MenuItem[]> {
    return this.menuItem$.asObservable();
  }

  private setMenu(): MenuItem[] {
    let menu = [
      {
        Label: 'MENU.LIST.DASHBOARD.TITLE',
        Icon: 'va-monitoring',
        RouterLink: 'dashboard',
        IsHidden: !this.userService.checkRoles(RoleBuilder.ROLES_DASHBOARD_POWER_BI),
        IsNode: false,
        Childs: [],
      },
      {
        Label: 'MENU.LIST.ASSETS.TITLE',
        Icon: 'va-assets',
        RouterLink: 'assets',
        IsHidden:
          !this.userService.checkRoles(RoleBuilder.ROLES_VEHICLES) &&
          !this.userService.checkRoles(RoleBuilder.ROLES_DOCKING_POINT) &&
          !this.userService.checkRoles(RoleBuilder.ROLES_IOT_MODULE) &&
          !this.userService.checkRoles(RoleBuilder.ROLES_BATTERIES),
        IsNode: true,
        Childs: [
          {
            Label: 'MENU.LIST.ASSETS.CHILD.VEHICLES',
            Icon: '',
            RouterLink: 'assets/vehicles',
            IsHidden: !this.userService.checkRoles(RoleBuilder.ROLES_VEHICLES),
            Childs: [],
          },
          {
            Label: 'MENU.LIST.ASSETS.CHILD.DOCKING_POINTS',
            Icon: '',
            RouterLink: 'assets/docking-points',
            IsHidden: !this.userService.checkRoles(RoleBuilder.ROLES_DOCKING_POINT),
            Childs: [],
          },
          {
            Label: 'MENU.LIST.ASSETS.CHILD.IOT_MODULES',
            Icon: '',
            RouterLink: 'assets/iot-modules',
            IsHidden: !this.userService.checkRoles(RoleBuilder.ROLES_IOT_MODULE),
            Childs: [],
          },
          {
            Label: 'MENU.LIST.ASSETS.CHILD.BATTERIES',
            Icon: '',
            RouterLink: 'assets/batteries',
            IsHidden: !this.userService.checkRoles(RoleBuilder.ROLES_BATTERIES),
            Childs: [],
          },
        ],
      },
      {
        Label: 'MENU.LIST.OTA_UPDATES_MANAGER.TITLE',
        Icon: 'pi pi-cloud-upload va-icon-size',
        RouterLink: 'ota-updates-manager',
        IsHidden: !this.userService.checkRoles(RoleBuilder.ROLES_OTA_UPDATES_MANAGER),
        Childs: [],
      },
      {
        Label: 'MENU.LIST.CUSTOMERS.TITLE',
        Icon: 'va-user',
        RouterLink: 'customers',
        IsHidden:
          !this.userService.checkRoles(RoleBuilder.ROLES_DASHBOARD) &&
          !this.userService.checkRoles(RoleBuilder.ROLES_PEOPLE) &&
          !this.userService.checkRoles(RoleBuilder.ROLES_COMPANIES) &&
          !this.userService.checkRoles(RoleBuilder.ROLES_MEMBERSHIP) &&
          !this.userService.checkRoles(RoleBuilder.ROLES_CUSTOMER_SATISFACTION),
        IsNode: true,
        Childs: [
          {
            Label: 'MENU.LIST.CUSTOMERS.CHILD.DASHBOARD',
            Icon: '',
            RouterLink: 'customers/dashboard',
            IsHidden: !this.userService.checkRoles(RoleBuilder.ROLES_DASHBOARD),
            Childs: [],
          },
          {
            Label: 'MENU.LIST.CUSTOMERS.CHILD.PEOPLE',
            Icon: '',
            RouterLink: 'customers/people',
            IsHidden: !this.userService.checkRoles(RoleBuilder.ROLES_PEOPLE),
            Childs: [],
          },
          {
            Label: 'MENU.LIST.CUSTOMERS.CHILD.COMPANIES',
            Icon: '',
            RouterLink: 'customers/companies',
            IsHidden: !this.userService.checkRoles(RoleBuilder.ROLES_COMPANIES),
            Childs: [],
          },
          {
            Label: 'MENU.LIST.CUSTOMERS.CHILD.MEMBERSHIPS',
            Icon: '',
            RouterLink: 'customers/memberships',
            IsHidden: !this.userService.checkRoles(RoleBuilder.ROLES_MEMBERSHIP),
            Childs: [],
          },
          {
            Label: 'MENU.LIST.CUSTOMERS.CHILD.CUSTOMER_SATISFACTION',
            Icon: '',
            RouterLink: 'customers/customer-satisfaction',
            IsHidden: !this.userService.checkRoles(RoleBuilder.ROLES_CUSTOMER_SATISFACTION),
            Childs: [],
          },
        ],
      },
      {
        Label: 'MENU.LIST.TRIPS.TITLE',
        Icon: 'va-trips',
        RouterLink: 'trips',
        IsHidden: !this.userService.checkRoles(RoleBuilder.ROLES_TRIPS),
        Childs: [],
      },
      {
        Label: 'MENU.LIST.MAINTENANCE.TITLE',
        Icon: 'va-maintenance',
        RouterLink: 'maintenance',
        IsHidden:
          !this.userService.checkRoles(RoleBuilder.ROLES_REPAIRS) &&
          !this.userService.checkRoles(RoleBuilder.ROLES_ISSUE) &&
          !this.userService.checkRoles(RoleBuilder.ROLE_MAINTENANCE_PLAN) &&
          !this.userService.checkRoles(RoleBuilder.ROLES_REDISTRIBUTION),
        IsNode: true,
        Childs: [
          {
            Label: 'MENU.LIST.MAINTENANCE.CHILD.REPAIRS',
            Icon: '',
            RouterLink: 'maintenance/repairs',
            IsHidden: !this.userService.checkRoles(RoleBuilder.ROLES_REPAIRS),
            Childs: [],
          },
          {
            Label: 'MENU.LIST.MAINTENANCE.CHILD.ISSUES',
            Icon: '',
            RouterLink: 'maintenance/issues',
            IsHidden: !this.userService.checkRoles(RoleBuilder.ROLES_ISSUE),
            Childs: [],
          },
          {
            Label: 'MENU.LIST.MAINTENANCE.CHILD.REDISTRIBUTION',
            Icon: '',
            RouterLink: 'maintenance/redistribution',
            IsHidden: !this.userService.checkRoles(RoleBuilder.ROLES_REDISTRIBUTION),
            Childs: [],
          },
          {
            Label: 'MENU.LIST.MAINTENANCE.CHILD.MAINTENANCE_PLANS',
            Icon: '',
            RouterLink: 'maintenance/maintenance-plans',
            IsHidden: !this.userService.checkRoles(RoleBuilder.ROLE_MAINTENANCE_PLAN),
            Childs: [],
          },
        ],
      },
      {
        Label: 'MENU.LIST.SHARING.TITLE',
        Icon: 'va-sharing',
        RouterLink: 'sharing',
        IsHidden:
          !this.userService.checkRoles(RoleBuilder.ROLES_STATION_PHYSICAL) &&
          !this.userService.checkRoles(RoleBuilder.ROLES_STATION_VIRTUAL) &&
          !this.userService.checkRoles(RoleBuilder.ROLES_PRICING_PLANS) &&
          !this.userService.checkRoles(RoleBuilder.ROLES_RESERVATIONS) &&
          !this.userService.checkRoles(RoleBuilder.ROLES_BOOKINGS),
        IsNode: true,
        Childs: [
          {
            Label: 'MENU.LIST.SHARING.CHILD.STATION',
            Icon: '',
            RouterLink: 'sharing/station',
            IsHidden:
              !this.userService.checkRoles(RoleBuilder.ROLES_STATION_PHYSICAL) &&
              !this.userService.checkRoles(RoleBuilder.ROLES_STATION_VIRTUAL),
            IsNode: true,
            Childs: [
              {
                Label: 'MENU.LIST.SHARING.CHILD.PHYSICAL',
                Icon: '',
                RouterLink: 'sharing/station/physical',
                IsHidden: !this.userService.checkRoles(RoleBuilder.ROLES_STATION_PHYSICAL),
                Childs: [],
              },
              {
                Label: 'MENU.LIST.SHARING.CHILD.VIRTUAL',
                Icon: '',
                RouterLink: 'sharing/station/virtual',
                IsHidden: !this.userService.checkRoles(RoleBuilder.ROLES_STATION_VIRTUAL),
                Childs: [],
              },
            ],
          },
          {
            Label: 'MENU.LIST.SHARING.CHILD.PRICING_PLANS',
            Icon: '',
            RouterLink: 'sharing/pricing-plans',
            IsHidden: !this.userService.checkRoles(RoleBuilder.ROLES_PRICING_PLANS),
            Childs: [],
          },
          {
            Label: 'MENU.LIST.SHARING.CHILD.RESERVATIONS',
            Icon: '',
            RouterLink: 'sharing/reservations',
            IsHidden: !this.userService.checkRoles(RoleBuilder.ROLES_RESERVATIONS),
            Childs: [],
          },
          {
            Label: 'MENU.LIST.SHARING.CHILD.BOOKINGS',
            Icon: '',
            RouterLink: 'sharing/bookings',
            IsHidden: !this.userService.checkRoles(RoleBuilder.ROLES_BOOKINGS),
            Childs: [],
          },
        ],
      },
      {
        Label: 'MENU.LIST.ACCOUNTING.TITLE',
        Icon: 'va-accounting',
        RouterLink: 'accounting',
        IsHidden:
          !this.userService.checkRoles(RoleBuilder.ROLES_PAYMENTS) ||
          !this.userService.checkRoles(RoleBuilder.ROLES_INVOICES) ||
          !this.userService.checkRoles(RoleBuilder.ROLES_CREDIT_CARDS) ||
          !this.userService.checkRoles(RoleBuilder.ROLES_BOOKKEEPING),
        IsNode: true,
        Childs: [
          {
            Label: 'MENU.LIST.ACCOUNTING.CHILD.PAYMENTS',
            Icon: '',
            RouterLink: 'accounting/payments',
            IsHidden: !this.userService.checkRoles(RoleBuilder.ROLES_PAYMENTS),
            Childs: [],
          },
          {
            Label: 'MENU.LIST.ACCOUNTING.CHILD.INVOICES',
            Icon: '',
            RouterLink: 'accounting/invoices',
            IsHidden: !this.userService.checkRoles(RoleBuilder.ROLES_INVOICES),
            Childs: [],
          },
          {
            Label: 'MENU.LIST.ACCOUNTING.CHILD.CREDIT_CARDS',
            Icon: '',
            RouterLink: 'accounting/credit-cards',
            IsHidden: !this.userService.checkRoles(RoleBuilder.ROLES_CREDIT_CARDS),
            Childs: [],
          },
          {
            Label: 'MENU.LIST.ACCOUNTING.CHILD.BOOKKEEPING',
            Icon: '',
            RouterLink: 'accounting/bookkeeping',
            IsHidden: !this.userService.checkRoles(RoleBuilder.ROLES_BOOKKEEPING),
            Childs: [],
          },
        ],
      },
      {
        Label: 'MENU.LIST.SEARCH.TITLE',
        Icon: 'va-search',
        RouterLink: 'search',
        IsHidden: !this.userService.checkRoles(RoleBuilder.ROLES_SEARCH),
        Childs: [],
      },
      {
        Label: 'MENU.LIST.CONFIGURATION.TITLE',
        Icon: 'va-config',
        RouterLink: 'configurations',
        IsHidden:
          !this.userService.checkRoles(RoleBuilder.ROLES_CONF_ORGANIZATIONS_TEAMS) &&
          !this.userService.checkRoles(RoleBuilder.ROLES_CONF_ORGANIZATIONS_USERS) &&
          !this.userService.checkRoles(RoleBuilder.ROLES_CONF_ASSETS_VEHICLE_MODELS) &&
          !this.userService.checkRoles(RoleBuilder.ROLES_CONF_ASSETS_IOT_MODULE_TYPES) &&
          !this.userService.checkRoles(RoleBuilder.ROLES_CONF_ASSETS_BATTERY_TYPES) &&
          !this.userService.checkRoles(RoleBuilder.ROLES_CONF_SYSTEM_LANGUAGES) &&
          !this.userService.checkRoles(RoleBuilder.ROLES_CONF_SYSTEM_BRANDING_COLORS) &&
          !this.userService.checkRoles(RoleBuilder.ROLES_CONF_SYSTEM_BRANDING_LOGOS) &&
          !this.userService.checkRoles(RoleBuilder.ROLES_CONF_SYSTEM_TEMPLATES) &&
          !this.userService.checkRoles(RoleBuilder.ROLES_CONF_SYSTEM_API_KEYS) &&
          !this.userService.checkRoles(RoleBuilder.ROLES_CONF_PAYMENTS_PAYMENT_GATEWAYS) &&
          !this.userService.checkRoles(RoleBuilder.ROLES_CONF_PAYMENTS_PRODUCTS) &&
          !this.userService.checkRoles(RoleBuilder.ROLES_CONF_SERVICE_ISSUE_CATEGORY),
        IsNode: true,
        Childs: [
          {
            Label: 'MENU.LIST.CONFIGURATION.CHILD.ORGANIZATIONS',
            Icon: '',
            RouterLink: 'configurations/organization',
            IsHidden:
              !this.userService.checkRoles(RoleBuilder.ROLES_CONF_ORGANIZATIONS_TEAMS) &&
              !this.userService.checkRoles(RoleBuilder.ROLES_CONF_ORGANIZATIONS_USERS),
            IsNode: true,
            Childs: [
              {
                Label: 'MENU.LIST.CONFIGURATION.CHILD.TEAMS',
                Icon: '',
                RouterLink: 'configurations/organization/teams',
                IsHidden: !this.userService.checkRoles(RoleBuilder.ROLES_CONF_ORGANIZATIONS_TEAMS),
                Childs: [],
              },
              {
                Label: 'MENU.LIST.CONFIGURATION.CHILD.USERS',
                Icon: '',
                RouterLink: 'configurations/organization/users',
                IsHidden: !this.userService.checkRoles(RoleBuilder.ROLES_CONF_ORGANIZATIONS_USERS),
                Childs: [],
              },
            ],
          },
          {
            Label: 'MENU.LIST.CONFIGURATION.CHILD.ASSETS',
            Icon: '',
            RouterLink: 'configurations/assets',
            IsHidden:
              !this.userService.checkRoles(RoleBuilder.ROLES_CONF_ASSETS_VEHICLE_MODELS) &&
              !this.userService.checkRoles(RoleBuilder.ROLES_CONF_ASSETS_IOT_MODULE_TYPES) &&
              !this.userService.checkRoles(RoleBuilder.ROLES_CONF_ASSETS_BATTERY_TYPES),
            IsNode: true,
            Childs: [
              {
                Label: 'MENU.LIST.CONFIGURATION.CHILD.VEHICLE_MODELS',
                Icon: '',
                RouterLink: 'configurations/assets/vehicle-models',
                IsHidden: !this.userService.checkRoles(RoleBuilder.ROLES_CONF_ASSETS_VEHICLE_MODELS),
                Childs: [],
              },
              {
                Label: 'MENU.LIST.CONFIGURATION.CHILD.IOT_MODULE_TYPES',
                Icon: '',
                RouterLink: 'configurations/assets/iot-module-types',
                IsHidden: !this.userService.checkRoles(RoleBuilder.ROLES_CONF_ASSETS_IOT_MODULE_TYPES),
                Childs: [],
              },
              {
                Label: 'MENU.LIST.CONFIGURATION.CHILD.BATTERY_TYPES',
                Icon: '',
                RouterLink: 'configurations/assets/battery-types',
                IsHidden: !this.userService.checkRoles(RoleBuilder.ROLES_CONF_ASSETS_BATTERY_TYPES),
                Childs: [],
              },
            ],
          },
          {
            Label: 'MENU.LIST.CONFIGURATION.CHILD.SYSTEM',
            Icon: '',
            RouterLink: 'configurations/system',
            IsHidden:
              !this.userService.checkRoles(RoleBuilder.ROLES_CONF_SYSTEM_LANGUAGES) &&
              !this.userService.checkRoles(RoleBuilder.ROLES_CONF_SYSTEM_BRANDING_COLORS) &&
              !this.userService.checkRoles(RoleBuilder.ROLES_CONF_SYSTEM_BRANDING_LOGOS) &&
              !this.userService.checkRoles(RoleBuilder.ROLES_CONF_SYSTEM_TEMPLATES) &&
              !this.userService.checkRoles(RoleBuilder.ROLES_CONF_SYSTEM_API_KEYS),
            IsNode: true,
            Childs: [
              {
                Label: 'MENU.LIST.CONFIGURATION.CHILD.LANGUAGES',
                Icon: '',
                RouterLink: 'configurations/system/languages',
                IsHidden: !this.userService.checkRoles(RoleBuilder.ROLES_CONF_SYSTEM_LANGUAGES),
                Childs: [],
              },
              {
                Label: 'MENU.LIST.CONFIGURATION.CHILD.BRANDING',
                Icon: '',
                RouterLink: 'configurations/system/branding',
                IsHidden:
                  !this.userService.checkRoles(RoleBuilder.ROLES_CONF_SYSTEM_BRANDING_COLORS) &&
                  !this.userService.checkRoles(RoleBuilder.ROLES_CONF_SYSTEM_BRANDING_LOGOS),
                IsNode: true,
                Childs: [
                  {
                    Label: 'MENU.LIST.CONFIGURATION.CHILD.COLORS',
                    Icon: '',
                    RouterLink: 'configurations/system/branding/colors',
                    IsHidden: !this.userService.checkRoles(RoleBuilder.ROLES_CONF_SYSTEM_BRANDING_COLORS),
                    Childs: [],
                  },
                  {
                    Label: 'MENU.LIST.CONFIGURATION.CHILD.LOGOS',
                    Icon: '',
                    RouterLink: 'configurations/system/branding/logos',
                    IsHidden: !this.userService.checkRoles(RoleBuilder.ROLES_CONF_SYSTEM_BRANDING_LOGOS),
                    Childs: [],
                  },
                ],
              },
              {
                Label: 'MENU.LIST.CONFIGURATION.CHILD.TEMPLATES',
                Icon: '',
                RouterLink: 'configurations/system/templates',
                IsHidden: !this.userService.checkRoles(RoleBuilder.ROLES_CONF_SYSTEM_TEMPLATES),
                Childs: [],
              },
              {
                Label: 'MENU.LIST.CONFIGURATION.CHILD.API_KEYS',
                Icon: '',
                RouterLink: 'configurations/system/api-keys',
                IsHidden: !this.userService.checkRoles(RoleBuilder.ROLES_CONF_SYSTEM_API_KEYS),
                Childs: [],
              },
            ],
          },
          {
            Label: 'MENU.LIST.CONFIGURATION.CHILD.PAYMENTS',
            Icon: '',
            RouterLink: 'configurations/payments',
            IsHidden:
              !this.userService.checkRoles(RoleBuilder.ROLES_CONF_PAYMENTS_PAYMENT_GATEWAYS) &&
              !this.userService.checkRoles(RoleBuilder.ROLES_CONF_PAYMENTS_PRODUCTS),
            IsNode: true,
            Childs: [
              {
                Label: 'MENU.LIST.CONFIGURATION.CHILD.PAYMENT_GATEWAYS',
                Icon: '',
                RouterLink: 'configurations/payments/payment-gateways',
                IsHidden: !this.userService.checkRoles(RoleBuilder.ROLES_CONF_PAYMENTS_PAYMENT_GATEWAYS),
                Childs: [],
              },
              {
                Label: 'MENU.LIST.CONFIGURATION.CHILD.PRODUCTS',
                Icon: '',
                RouterLink: 'configurations/payments/products',
                IsHidden: !this.userService.checkRoles(RoleBuilder.ROLES_CONF_PAYMENTS_PRODUCTS),
                Childs: [],
              },
            ],
          },
          {
            Label: 'MENU.LIST.CONFIGURATION.CHILD.SERVICE',
            Icon: '',
            RouterLink: 'configurations/service',
            IsHidden: !this.userService.checkRoles(RoleBuilder.ROLES_CONF_SERVICE_ISSUE_CATEGORY),
            IsNode: true,
            Childs: [
              {
                Label: 'MENU.LIST.CONFIGURATION.CHILD.ISSUE_CATEGORY',
                Icon: '',
                RouterLink: 'configurations/service/issue-category',
                IsHidden: !this.userService.checkRoles(RoleBuilder.ROLES_CONF_SERVICE_ISSUE_CATEGORY),
                Childs: [],
              },
            ],
          },
        ],
      },
      {
        Label: '',
        Childs: [],
        IsHidden: false,
        IsSpacer: true,
      },
      {
        Label: 'MENU.LIST.TENANT.TITLE',
        Icon: 'va-tenant',
        RouterLink: 'tenants',
        Childs: [],
        IsHidden: !this.userService.checkRoles([]),
        IsTenant: true,
        ItemFirst: true,
      },
      {
        Label: 'MENU.LIST.USER.TITLE',
        Icon: '',
        RouterLink: 'user-profile',
        Childs: [],
        IsHidden: false,
        IsUser: true,
        IsNode: true,
      },
    ];

    return this.removeHiddenElements(menu);
  }

  private removeHiddenElements(arr: MenuItem[]): MenuItem[] {
    return arr
      .filter((a) => !a.IsHidden)
      .map((e) => {
        return { ...e, Childs: this.removeHiddenElements(e.Childs || []) };
      });
  }

  private getConfig(): void {
    this.subscription = this.configService.getConfig().subscribe((config) => {
      if (config) {
        this.menuItem$.next(this.setMenu());
      }
    });
  }
}
