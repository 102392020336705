<i class="pi pi-filter icon-pointer" (click)="panel.toggle($event)"></i>
<p-popover #panel showCloseIcon="true" [style]="overlayStyle">
  <ul class="item-ot">
    @for (o of operators; track o) {
      <li class="desc">
        <p-radioButton
          [id]="o.key"
          [inputId]="o.key"
          [value]="o"
          [(ngModel)]="selected"
          (click)="opChange.emit(o)"
        ></p-radioButton>
        <label [for]="o.key">{{ o.label | translate }}</label>
      </li>
    }
  </ul>
</p-popover>
