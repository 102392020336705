import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  inject,
  viewChild, ChangeDetectorRef,
} from '@angular/core';
import { SystemService } from '@codegen/Vaimoo.Admin.API';
import { environment } from 'src/environments/environment';
import { MenuItem } from '../models/menu-item.model';
import { UserOverlayComponent } from './user-overlay.component';
import { TooltipModule } from 'primeng/tooltip';
import { RouterModule } from '@angular/router';
import { AsyncPipe, NgClass, NgStyle } from '@angular/common';
import { UserThumbsComponent } from '@shared/components/user-thumbs.component';
import { TranslateModule } from '@ngx-translate/core';
import { map, Observable } from 'rxjs';

@Component({
  selector: 'va-sidebar',
  template: `
    <div class="sidebar">
      <a href="/" class="sidebar-logo" [pTooltip]="version$ | async" [tooltipPosition]="'right'"></a>
      @if (menuItems && menuItems.length > 0) {
      <ul class="sidebar-menu">
        @for (menu of menuItems; track menu; let i = $index) {
        <li
          [class.sidebar-items]="!menu?.IsSpacer"
          [class.spacer]="menu?.IsSpacer"
          (click)="menu.IsUser ? userOverlay().userDetails.toggle($event) : ''"
        >
          <a
            #menu
            class="sidebar-link"
            #firstLevel="routerLinkActive"
            [routerLinkActive]="'active-sidebar'"
            [routerLink]="!menu.IsUser && menu.Childs.length === 0 ? menu.RouterLink : null"
            [pTooltip]="menu.Label | translate"
            [tooltipPosition]="'right'"
            (click)="selectFromMenu(menu)"
          >
            <i class="va-icon" [ngClass]="menu?.Icon" [class.va-active]="firstLevel.isActive"></i>
            @if (menu?.IsUser) {
            <div style="padding: 7px">
              <va-user-thumbs
                [firstname]="userInfo?.firstName"
                [lastname]="userInfo?.lastName"
              ></va-user-thumbs>
              <va-user-overlay #userOverlay [userInfo]="userInfo"></va-user-overlay>
            </div>
            }
          </a>
        </li>
        }
      </ul>
      }
    </div>
  `,
  standalone: true,
  imports: [
    TooltipModule,
    RouterModule,
    NgClass,
    UserThumbsComponent,
    UserOverlayComponent,
    TranslateModule,
    AsyncPipe,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarComponent implements OnInit {
  private systemService = inject(SystemService);
  private cd = inject(ChangeDetectorRef);

  readonly userOverlay = viewChild<UserOverlayComponent>('userOverlay');

  @Input() menuItems: MenuItem[];
  @Input() userInfo: any;
  @Output() menuOnChange: EventEmitter<MenuItem> = new EventEmitter<MenuItem>();

  version$ = new Observable<string>();

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);

  constructor() {
    this.version$ = this.systemService.pingGet().pipe(
      map((res) => {
        return `FE: v${environment.buildNumber} (${environment.release}) | BE: v${res.version}`;
      }),
    );
  }

  ngOnInit(): void {
    console.log();
  }

  selectFromMenu(menu: MenuItem) {
    this.menuOnChange.emit(menu);
    this.cd.markForCheck();
  }
}
