import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ColumnTable } from '@shared/interfaces/column-table.interface';
import { ButtonEventComponent } from '../button-event.component';
import { TooltipModule } from 'primeng/tooltip';
import { CheckboxModule } from 'primeng/checkbox';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { PopoverModule } from 'primeng/popover';

@Component({
    selector: 'va-column-settings-overlay',
    template: `
    <va-button-event
      [pTooltip]="'COMMONS.COLUMNS.TITLE' | translate"
      [tooltipPosition]="'top'"
      [icon]="'pi pi-cog'"
      (clickIcon)="list.toggle($event)"
    ></va-button-event>
    <p-popover #list showCloseIcon="true" [style]="overlayCellsStyle">
      <ul class="column-settings">
        @for (item of items; track item) {
          <li class="column-item">
            @if (!item.action) {
              <div class="flex align-items-center gap-1">
                <p-checkbox
                  [id]="item.header"
                  class="item"
                  [(ngModel)]="item.visible"
                  (ngModelChange)="itemsChange.emit(items)"
                  [disabled]="disabled && item.visible"
                  binary="true"
                  >
                </p-checkbox>
                <label [for]="item.header">{{ item.header | translate }}</label>
              </div>
            }
          </li>
        }
      </ul>
    </p-popover>
    `,
    standalone: true,
    imports: [
        ButtonEventComponent,
        TooltipModule,
        PopoverModule,
        CheckboxModule,
        FormsModule,
        TranslateModule,
    ],
})
export class ColumnSettingsOverlayComponent implements OnInit {
  @Input() class: string;
  @Input() items: ColumnTable[];
  @Output() itemsChange: EventEmitter<ColumnTable[]> = new EventEmitter<ColumnTable[]>();

  overlayCellsStyle = {
    backgroundColor: '#fff',
    boxShadow: '0px 10px 20px -10px rgba(0, 0, 0, 0.75)',
    width: 'auto',
    margin: 0,
  };

  constructor() {}

  ngOnInit(): void {
    console.log();
  }

  get disabled(): boolean {
    let visible = this.items.filter((el) => !el.visible).length;
    let media = this.items.length / 2;

    return visible > media;
  }
}
