import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'optionValuePipe',
    standalone: true,
})
export class OptionalValuePipe implements PipeTransform {
  transform(value: any, append?: string): any {
    return this.elaborate(value, append ? append : '-');
  }

  private elaborate(value: any, append?: string) {
    if (this.isNullOrEmptyString(value)) {
      return append;
    }
    return `${value}`;
  }

  private isNullOrEmptyString(val: any): boolean {
    return val === null || val === '' || val === undefined;
  }
}
